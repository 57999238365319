import React, { useState, useEffect } from 'react';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, TextField, Button, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Paper, FormControl, InputLabel, Select, MenuItem,Grid, FormControlLabel, Checkbox, FormGroup, FormHelperText } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate  } from 'react-router-dom'; 
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import { useDropzone } from 'react-dropzone';
import {countryCodes} from '../common/countries';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const QuotePage = () => {

     const navigate = useNavigate(); 
 
     const { user } = useAuth();
    
    const { email, name, roles, userTypes} =  user || {};
    
  const [orders, setOrders] = useState([]);
  const [quotes, setQuotes] = useState([]);
    
  const [formData, setFormData] = useState({
    name: name,
    email: email,
    message: '',
    attachment: null,
    altPrimaryName:  '',
   altPrimaryEmail: '',
    altPrimaryCountryCode: '',
   altPrimaryPhoneNumber: '',
      dataPrivacy: false,
  });

    const [differentPrimaryContact, setDifferentPrimaryContact] = useState(false);
 

      // Fetch company types and user types from the API when component mounts
  useEffect(() => {
          if (user) {
            setFormData({
                email,
                name
            });
        }
       }, [user, email, name]);
      
  const [errors, setErrors] = useState({
    nameError: '',
    emailError: '',
    messageError: '',
    serverError: '',
  });

    
    // Handle file upload via button or dropzone
  const handleFileChange = (file) => {
    setFormData({ ...formData, attachment: file });
  };

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
    accept: {
      'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-pki.stl': ['.stl'],
  'application/vnd.ms-package.3dxml': ['.3dxml'],
  'application/octet-stream': ['.dwg', '.step', '.stp', '.sldprt', '.dxf', '.ipt', '.x_t', '.x_b', '.catpart', '.prt', '.sat', '.3mf', '.jt', '.iges'],
    },
    maxSize: 10 * 1024 * 1024, // 10 MB
  });
    
    

    const [touched, setTouched] = useState(false); 
    const handleCheckboxChange = (event) => { setFormData({ ...formData, dataPrivacy: event.target.checked }); setTouched(true); };
    
  // Handle form submission
    
    
  const submitForm = (event) => {
    event.preventDefault(); // Prevent default form submission
      
      

    const data = new FormData(document.getElementById("requestQuoteForm"));
    
    // Validate form fields
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
      
    const phoneNumber = document.getElementById("phoneNumber").value;
     const countryCode = document.getElementById("countryCode").value;
      
     const checkbox = document.getElementById('dataPrivacy'); 
      
  //  const altPrimaryPhoneNumber = document.getElementById("altPrimaryPhoneNumber").value;
      
   //   alert(altPrimaryPhoneNumber);
      
      //console.log("attachment :  "+ formData.attachment.name);
       
 
        
      if(formData.attachment){
          data.append('attachment',formData.attachment );
          data.append("fileName", formData.attachment.name);
         }
      
      
    // Reset previous error messages
    const errorElements = document.getElementsByClassName('error');
    for (let i = 0; i < errorElements.length; i++) {
        errorElements[i].innerHTML = '';
    }

    if (!name) {
        document.getElementById('nameError').innerHTML = 'Name is required';
        return;
    } 

    if (!email || !isValidEmail(email)) {
        document.getElementById('emailError').innerHTML = 'Invalid Email ID';
        return;
    } 
      
      
    if (!message) {
        document.getElementById('messageError').innerHTML = 'Message is required';
        return;
    }
      
    
      
        data.append("dataPrivacy", true);
    
    console.log(data);
  axiosInstance.post(`${backendUrl}/api/quote-request`,data)
      .then(response => {
    console.log(response.data);
         console.log(response.status);
    if (response.status === 201) { // Check status instead of response.ok
      alert("Thank you for Requesting a Quote!! We will get back to you ASAP.");
      document.getElementById("requestQuoteForm").reset();
      setFormData({ 
          countryCode: "",
          message: '',
    attachment: null,
    altPrimaryName:  '',
   altPrimaryEmail: '',
    altPrimaryCountryCode: "",
   altPrimaryPhoneNumber: '',
         dataPrivacy: false,
      }); // Reset form data here
      return;
    }
    // Process custom message if present
    if (response.data && typeof response.data.customMessage !== 'undefined') {
      alert(response.data.customMessage);
    }
  })
  .catch(error => {
    console.error('Error submitting the form:', error);
    alert("Internal Error, Try again later or Please contact administrator.!!!");
  });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
    

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
      
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '20px' }}>
      <Paper elevation={3} sx={{ padding: '30px', maxWidth: '600px', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Request a Quote
        </Typography>
        <form id="requestQuoteForm" onSubmit={submitForm} encType="multipart/form-data">
          {/* Server Error */}
          {errors.serverError && (
            <Typography variant="body2" color="error">
              {errors.serverError}
            </Typography>
          )}

          {/* Name Field */}
          <TextField
            label="Your Name"
            id="name"
            name="name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            error={!!errors.nameError}
            helperText={errors.nameError}
    InputProps={{
        readOnly: true,
    }}
          />

          {/* Email Field */}
          <TextField
            label="Your Email"
            id="email"
            name="email"
            type="email"
            fullWidth
            margin="normal"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            error={!!errors.emailError}
            helperText={errors.emailError}
    InputProps={{
        readOnly: true,
    }}
          />




   {/* Error Message for Phone Number */}
      <div id="iphoneNumberError" className="error">{errors.phoneNumber}</div>

      {/* Phone Number Container */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="countryCode-label">Country Code</InputLabel>
        <Select
          labelId="countryCode-label"
          id="countryCode"
          name="countryCode"
          value={formData.countryCode}
          onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
          required
        >
          <MenuItem value="">Select Country Code</MenuItem>
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.code} ({country.country})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type="tel"
        id="phoneNumber"
        name="phoneNumber"
        label="Phone Number"
        placeholder="Phone Number"
        value={formData.phoneNumber}
        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
        fullWidth
        margin="normal"
        required
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber}
      />
        



          {/* Message Field */}
          <TextField
            label="Your Message"
            id="message"
            name="message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            required
            error={!!errors.messageError}
            helperText={errors.messageError}
          />




 {/* Attachment Field */}
      <Typography variant="body2" gutterBottom>
        Attachment: (Allowed types: .pdf, .dwg, .step, .stp, .sldprt, .dxf, .ipt, .x_t, .x_b, .catpart, .prt, .sat, .3mf, .jt, .iges, .doc, .docx, .png, .jpeg, .jpg, .ppt, .pptx, .txt, .3dxml Up to 10 MB)
      </Typography>

      {/* Dropzone Area */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2">Drop the files here ...</Typography>
        ) : (
          <Typography variant="body2">
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        )}
      </Box>

      {/* Upload Button */}
      <Button variant="outlined" component="label" sx={{ marginBottom: '20px' }}>
        Upload Attachment
        <input
          type="file"
          hidden
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
      </Button>

      {/* Show Uploaded File */}
      {formData.attachment && (
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Uploaded file: {formData.attachment.name}
        </Typography>
      )}


          {/* Consent */}
          <Typography variant="body2" gutterBottom>
            In order to provide your quote, we need your consent to communicate with you and to store and process your personal data. You can unsubscribe or ask us to remove your data at any time.
          </Typography>

<FormControl required error={touched && !formData.dataPrivacy} component="fieldset">
    <FormGroup>
    <FormControlLabel control={<Checkbox checked={formData.dataPrivacy} onChange={handleCheckboxChange} required/>} label="I accept the data privacy terms" />
        </FormGroup> {touched && !formData.dataPrivacy && <FormHelperText>Please accept data privacy terms.</FormHelperText>}
    </FormControl>

  <FormControlLabel
        control={
          <Checkbox
            checked={differentPrimaryContact}
            onChange={(e) => setDifferentPrimaryContact(e.target.checked)}
            color="primary"
          />
        }
        label="Primary contact is different from you"
      />

      {differentPrimaryContact && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Name"
              id="altPrimaryName"
              name="altPrimaryName"
              fullWidth
              value={formData.altPrimaryName}
           onChange={(e) => setFormData({ ...formData, altPrimaryName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Email"
              id="altPrimaryEmail"
              name="altPrimaryEmail"
              type="email"
              fullWidth
              value={formData.altPrimaryEmail}
            onChange={(e) => setFormData({ ...formData, altPrimaryEmail: e.target.value })}
            />
          </Grid>


          <Grid item xs={12} sm={6}>
   {/* Error Message for Phone Number */}
      <div id="altPrimaryCountryCodeError" className="error">{errors.altPrimaryPhoneNumber}</div>

      {/* Phone Number Container */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="altPrimaryCountryCode-label">Country Code</InputLabel>
        <Select
          labelId="altPrimaryCountryCode-label"
          id="altPrimaryCountryCode"
          name="altPrimaryCountryCode"
          value={formData.altPrimaryCountryCode}
          onChange={(e) => setFormData({ ...formData, altPrimaryCountryCode: e.target.value })}
        >
          <MenuItem value="">Select Country Code</MenuItem>
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.code} ({country.country})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Phone"
              id="altPrimaryPhoneNumber"
              name="altPrimaryPhoneNumber"
              fullWidth
              value={formData.altPrimaryPhoneNumber}
              onChange={(e) => setFormData({ ...formData, altPrimaryPhoneNumber: e.target.value })}
            />
          </Grid>
        </Grid>
      )}
      

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
</Box>
</Box>
  );
};

export default QuotePage;
