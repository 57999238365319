import React, { useState } from 'react';
import { Button, TextField, Grid, AppBar, Toolbar, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';

const InternalQuote = () => {
  const [uniqueId, setUniqueId] = useState('');
  const [message, setMessage] = useState('');
    
  const navigate = useNavigate();

         const { user } = useAuth();
    const { email, name, userId, roles, userTypes} =  user || {};
    
  const handleCopyMessage = () => {
    navigator.clipboard.writeText(message);
      
        axiosInstance.get(`/api/quote-request/${uniqueId}`)
        .then((response) => {
            
            if(response.data){
                setMessage(response.data.message);
               }else{
                  setMessage('');
                   alert('No matching Quote! Try again ');
               }
         
        })
        .catch((error) => {
          console.error('Error fetching quotes:', error);
        });
      
  };
    
     // Function to handle going back
  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleNext = () => {
    if (uniqueId) {
      navigate('/select-users', { state: { uniqueId, message  } });
    } else {
      alert('Please enter a Unique ID!');
    }
  };

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
      
    <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
      <Typography variant="h5">Estimation Page</Typography>
      <TextField
        label="Enter Unique ID"
        value={uniqueId}
        onChange={(e) => setUniqueId(e.target.value)}
        fullWidth
      />
      <Button variant="outlined" onClick={handleCopyMessage}>
        Copy Quote
      </Button>
      <TextField
        label="Message Text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        rows={4}
        fullWidth
      />
         
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Button variant="contained" color="secondary" onClick={handleBack}>
          Back
        </Button>
      </Grid>
      
      <Grid item>
          <Button variant="contained" onClick={handleNext}>
        Next
      </Button>
      </Grid>
    </Grid>
   
    </Grid>
</Box>

</Box>
  );
};

export default InternalQuote;
