import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from "@mui/material";
import TermPdfViewer from "./TermPdfViewer";
import TermsPopupDialog from '../components/TermsPopupDialog';
import LeftDrawer from '../components/LeftDrawer';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';

const TermsDropdownViewer = ({ onTermSelect }) => {
  const [terms, setTerms] = useState([]); // List of terms
  const [selectedTerm, setSelectedTerm] = useState(""); // Selected term ID
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog

  const { user } = useAuth();
  const { email, name, roles, userTypes,userId} =  user || {};
  const navigate = useNavigate(); 
    
  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    try {
      const response = await axiosInstance.get("/api/terms"); // Fetch terms list
      setTerms(response.data);
    } catch (error) {
      console.error("Error fetching terms:", error);
      alert("Failed to load terms.");
    }
  };

  const handleTermChange = (event) => {
      const value = event.target.value;
    //  console.log(t.id);
    //   console.log(t.filePath);
    setSelectedTerm(value); // Update selected term
    if(onTermSelect){
      onTermSelect(value); // Notify the parent component
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    fetchTerms(); // Refresh terms list after dialog closes
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        View Terms and Conditions
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="terms-select-label">Select a Term</InputLabel>
        <Select
          labelId="terms-select-label"
          value={selectedTerm}
          onChange={handleTermChange}
          label="Select a Term"
        >
          {terms.map((term) => (
            <MenuItem key={term.id} value={term.filePath}>
              {term.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setDialogOpen(true)}
        sx={{ mb: 3 }}
      >
        Add Term
      </Button>
      {selectedTerm ? (
        <TermPdfViewer pdfId={selectedTerm} />
      ) : (
        <Typography variant="body1">Select a term to view its PDF.</Typography>
      )}
      <TermsPopupDialog open={dialogOpen} onClose={handleDialogClose} onSuccess={fetchTerms} />
    </Box>
  );
};

export default TermsDropdownViewer;
