// LeftDrawer.js
import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';

const drawerWidth = 240;

const LeftDrawer = ({ handleDashboard,handleProfile, handleRequestQuote, handleQuoteList , handleOrders, handleTracker, handleDealer,handleDealers, handleSupplier,handleSuppliers, handleManufacturer, handleManufacturers, handlePurchaseOrder, userRoles, userTypes, handleSendEstimate, handleEstimate, handlePartner, handleInvoice, handleTrader, handleBuilder, handlePartnerView, handleInvoiceView, handlePurchaseOrderList, handleReport, handlePartGroup, handleParts, handleBuilders,handleTraders, handleGRN }) => {

    
    
// Inside your component
useEffect(() => {
  console.log("userTypes:", userTypes);
    console.log("userRoles:", userRoles);
}, [userTypes,userRoles]); // This will log userTypes every time it changes
    
  return (
<Drawer
  sx={{
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      top: 'auto',  // Ensures it is below the header
      height: '70%', // Allow it to fill the height of the page
      overflowY: 'auto', // Make the content scrollable
      '@media (max-height: 800px)': {
        height: '80%', // Allow it to fill the height of the page
        overflowY: 'scroll', // Make the content scrollable
      },
    },
  }}
  variant="permanent"
  anchor="left"
>
      <List>
       <ListItem button onClick={handleDashboard}>
          <ListItemText primary="Dashboard" />
        </ListItem>
             <ListItem button onClick={handleProfile}>
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem button onClick={handleRequestQuote}>
          <ListItemText primary="Request Quote" />
        </ListItem>
        <ListItem button onClick={handleQuoteList}>
          <ListItemText primary="Quote List" />
        </ListItem>
        <ListItem button onClick={handleOrders}>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button onClick={handleTracker}>
          <ListItemText primary="Tracker" />
        </ListItem>
      
    {Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_PARTNER') && (  
     <div>
  <ListItem button onClick={handlePartner}>
    <ListItemText primary="Partner" />
  </ListItem>
      </div>
      )}
      
       {/* Show Quote List only for Dealer role */}
{Array.isArray(userTypes) && userTypes.some(userType => userType.typeName === 'Dealer' || userType.role === 'ROLE_ADMIN') && (
  <ListItem button onClick={handleDealer}>
    <ListItemText primary="Dealer" />
  </ListItem>
)}

{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
  <ListItem button onClick={handleDealers}>
    <ListItemText primary="Dealers" />
  </ListItem>
)}
   {/* Show Quote List only for admin role */}
        { Array.isArray(userTypes) && (userTypes.some(userType => userType.typeName === 'Supplier'  || userType.role === 'ROLE_ADMIN')) && (
              <ListItem button onClick={handleSupplier}>
          <ListItemText primary="Supplier" />
        </ListItem>
              )}



{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
    <>
     <div>
      <ListItem button onClick={handleSuppliers}>
    <ListItemText primary="Suppliers" />
  </ListItem>
     </div>
     <div>
      <ListItem button onClick={handlePurchaseOrder}>
    <ListItemText primary="Purchase Order" />
  </ListItem>
     </div>
     <div>
     <ListItem button onClick={handlePurchaseOrderList}>
    <ListItemText primary="Purchase Order List" />
  </ListItem>
     </div>
    <div>
  <ListItem button onClick={handleInvoice}>
    <ListItemText primary="Invoice" />
  </ListItem>
      </div>
<div>
  <ListItem button onClick={handleInvoiceView}>
    <ListItemText primary="Invoice View" />
  </ListItem>
    </div>
    </>
)}
     

      
       {/* Show Quote List only for admin role */}
        {Array.isArray(userTypes) && userTypes.some(userType => userType.typeName === 'Manufacturing'  || userType.role === 'ROLE_ADMIN') && (
           <ListItem button onClick={handleManufacturer}>
          <ListItemText primary="Manufacturer" />
        </ListItem>
        )}

{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
  <ListItem button onClick={handleManufacturers}>
    <ListItemText primary="Manufacturers" />
  </ListItem>
)} 

      
       {/* Show Trader for admin role */}
        {Array.isArray(userTypes) && (userTypes.some(userType => userType.typeName === 'Trader'  || userType.role === 'ROLE_ADMIN')) && (
           <ListItem button onClick={handleTrader}>
          <ListItemText primary="Trader" />
        </ListItem>
        )}

{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
  <ListItem button onClick={handleTraders}>
    <ListItemText primary="Traders" />
  </ListItem>
)}


       {/* Show Trader for admin role */}
        {Array.isArray(userTypes) && userTypes.some(userType => userType.typeName === 'Builder'  || userType.role === 'ROLE_ADMIN') && (
           <ListItem button onClick={handleBuilder}>
          <ListItemText primary="Builder" />
        </ListItem>
        )}

{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
  <ListItem button onClick={handleBuilders}>
    <ListItemText primary="Builders" />
  </ListItem>
)}


{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'ROLE_ADMIN') && (
    <>
     <div>
  <ListItem button onClick={handleSendEstimate}>
    <ListItemText primary="Send For Estimate" />
  </ListItem>
      </div>

 <div>
  <ListItem button onClick={handleEstimate}>
    <ListItemText primary="Estimate" />
  </ListItem>
      </div>

     <div>
  <ListItem button onClick={handlePartGroup}>
    <ListItemText primary="Part Group" />
  </ListItem>
      </div>

     <div>
  <ListItem button onClick={handleParts}>
    <ListItemText primary="Parts" />
  </ListItem>
      </div>

     <div>
  <ListItem button onClick={handlePartner}>
    <ListItemText primary="Partner" />
  </ListItem>
      </div>

     <div>
  <ListItem button onClick={handlePartnerView}>
    <ListItemText primary="PartnerView" />
  </ListItem>
      </div>
    
         <div>
  <ListItem button onClick={handleGRN}>
    <ListItemText primary="Good Receipt Note" />
  </ListItem>
      </div>

     <div>
  <ListItem button onClick={handleReport}>
    <ListItemText primary="Report" />
  </ListItem>
      </div>
    </>
)}

      </List>
    </Drawer>
  );
};

export default LeftDrawer;
