import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // or useNavigate from react-router-dom v6
import { useAuth } from '../context/AuthContext';
import axios from 'axios';  // For API calls
import axiosInstance from '../common/axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const VerificationPopup = ({ open, handleClose }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // or useNavigate for react-router-dom v6

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

    
      const { user , login } = useAuth();
    
const { email, name, givenName, familyName, provider,emailVerified, userId } = user || {};
    
    
   const handleResend = () => {
         setError('');
         axiosInstance.post(`${backendUrl}/api/users/${userId}/sendOTP`)
      .then(response => {
             
             console.log("my status : "+ response.status);
        if(response.status == 201){     
      setError('Sent Verification Code to your email');
    } else {
      setError('Now able to send verification code');
    }
              
          })
      .catch(error => console.error('Error fetching statuses:', error));
       
   };
    
    
  const handleVerify = () => {
    // Simulate verification process
      
          axiosInstance.post(`/api/users/${userId}/validate/${verificationCode}`)
      .then(response => {
              
                 const user = response.data;
   
              
               if (response.data.emailVerified) { // Replace with actual verification logic
                        login(user);
      setError('');
      handleClose();
      navigate('/dashboard'); // Navigate to dashboard
    } else {
      setError('Invalid verification code');
    }
              
          })
      .catch(error => console.error('Error fetching statuses:', error));
      
  
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enter Verification Code</DialogTitle>
      <DialogContent>Verification Code is sent your registred email. 
        <TextField
          fullWidth
          margin="normal"
          label="Verification Code"
          value={verificationCode}
          onChange={handleVerificationCodeChange}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
      <Button onClick={handleResend} color="primary">Resend Code</Button>
        <Button onClick={handleVerify} color="primary">Verify</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationPopup;
