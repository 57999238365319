import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Button, Grid  } from '@mui/material';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReEstimationView = () => {
      const location = useLocation();
    
       
     const [reEstimations, setEstimations] = useState([]);
     const navigate = useNavigate(); 
       const { user } = useAuth();
    
 const { email, name, userId, roles, userTypes} =  user || {};
     
    const {estimationId }  = location.state;
    
      useEffect(() => {
     
      fetchEstimation();
      
  }, [email]);
    
    
      const fetchEstimation = async () => {
    if (email) {
      // Fetch the quotes from API based on the user's email
        
        let estimationURL = `${backendUrl}/api/reestimations/${estimationId}`  
        if(Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')){
            
            console.log("admin estim url ")
           estimationURL = `${backendUrl}/api/reestimations/${estimationId}`
           }
        
      axios.get(estimationURL)
        .then((response) => {
          setEstimations(response.data);
        })
        .catch((error) => {
          console.error('Error fetching quotes:', error);
        });
    }
      }
      
        const handleDownload = (fileLink,fileName) => {
  
    if(fileLink === null || fileLink === undefined || fileLink === "" ){
        
        alert("There is no attachment");
        return;
    }
    
     axios({
      url: `${backendUrl}/api/reestimations/download?filePath=${fileLink}`, // Replace with your backend URL
      method: 'GET',
      responseType: 'blob', // Important to specify blob response
    })
    .then((response) => {
      // Create a URL from the response blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Suggest the filename
      link.setAttribute('download', fileName); // Replace with your desired file name
      document.body.appendChild(link);
      
      // Start download
      link.click();
      
      // Clean up
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('There was an error downloading the file!', error);
    });
    
}
    
    
  const columns = [
    { field: 'estimationUniqueId', headerName: 'Estimation ID', width: 150 },
    { field: 'requestorName', headerName: 'Requestor Name', width: 200 },
    { field: 'comments', headerName: 'Comments', width: 300 },
    { field: 'reestimationDetails', headerName: 'Re-estimation Details', width: 300 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'updatedAt', headerName: 'Updated At', width: 180 },
    {
      field: 'download', 
      headerName: 'Download', 
      width: 150,
      renderCell: (params) => (
     
      <Button
        variant="text"
        color="primary"
        onClick={() => handleDownload(params.row.estimationFileLink, params.row.estimationFileName)}
      >
        Download/View
      </Button>
      )
    },
 
  ];


 

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={reEstimations}
        columns={columns}
        getRowId={(row) => row.reestimationId} // Use reestimationId as the unique row ID
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
</Box>
</Box>
  );
};

export default ReEstimationView;
