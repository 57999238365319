import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Rating, Typography } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../common/axios';

const CustomerReviewPopup = ({ open, onClose, userId, orderId, type }) => {
  const [rating, setRating] = useState(0);
  const [reviewComments, setReviewComments] = useState('');

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleReviewChange = (event) => {
    setReviewComments(event.target.value);
  };

  const handleSubmit = async () => {
    if (rating === 0 || reviewComments.trim() === '') {
      alert("Please provide a rating and a review.");
      return;
    }
    try {
      await axiosInstance.post(`/api/order-reviews`, { userId, orderId, rating, reviewComments });
      alert("Thank you for your feedback!");
      onClose(); // Close popup after submission
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("Failed to submit review. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Submit Your Review</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Rate our service:
        </Typography>
        <Rating
          name="customer-rating"
          value={rating}
          onChange={handleRatingChange}
          precision={1}
          size="large"
        />
        <TextField
          label="Review Comments"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
          value={reviewComments}
          onChange={handleReviewChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit Review
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerReviewPopup;
