import React, { useState } from 'react';
import {  AppBar, Toolbar, Box, TextField, Button, Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import { DataGrid } from '@mui/x-data-grid';

const ReviewPage = () => {
  const location = useLocation();
       const navigate = useNavigate(); 
  const { uniqueId, message, selectedPartners } = location.state;
  const { user } = useAuth();
  const { email, name, userId, roles, userTypes} =  user || {};
  const [searchText, setSearchText] = useState('');
    
  // Filter partners based on search input
  const filteredPartners = selectedPartners.filter(
    (partner) =>
      partner.companyName.toLowerCase().includes(searchText.toLowerCase()) ||
      partner.city.toLowerCase().includes(searchText.toLowerCase()) ||
      partner.country.toLowerCase().includes(searchText.toLowerCase())
  );

  // Define columns for DataGrid
  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 200 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    {
      field: 'types',
      headerName: 'Types',
      width: 200,
          renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const types = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.type).join(', ') 
      : 'No types'; // Fallback if no capabilities

    return <span>{types}</span>; // Render the capabilities as a span
  },
    },
      {
      field: 'capabilities',
      headerName: 'Capabilities',
      width: 200,
           renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const capabilities = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.name).join(', ') 
      : 'No capabilities'; // Fallback if no capabilities

    return <span>{capabilities}</span>; // Render the capabilities as a span
  },
    },
    {
      field: 'users',
      headerName: 'Users',
      width: 250,
       renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const users = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.name).join(', ') 
      : 'No users'; // Fallback if no capabilities

    return <span>{users}</span>; // Render the capabilities as a span
  },
    
    },

    
  ];
    
     // Function to handle going back
  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };
    
  const handleSubmit = () => {
   // alert(`Estimation sent to: ${selectedPartners.map((user) => user.partnerId).join(', ')}`);
    // Here, you can add the logic to submit the estimation to the server
      
            
const formData = new FormData(document.getElementById("requestEstimationForm"));
      
      // Create the JSON object
const estimationData = {
       userId:userId,
    quoteRef: uniqueId,
    estimationDetails: message,
    partnerIds: selectedPartners.map(partner => partner.partnerId)
};
      
formData.append('requestEstimation', new Blob([JSON.stringify(estimationData)], { type: 'application/json' }));


// Send the POST request using Axios
axiosInstance.post('/api/estimations/estimate', formData)
    .then((response) => {
        console.log('Success:', response.data);  // Handle the response data
    })
    .catch((error) => {
        console.error('Error:', error);  // Handle any errors
    });
      
  };
    
    
      const [formData, setFormData] = useState({
    quoteRef: uniqueId,
    message: message,
    attachment: null,
  });
    
      const [errors, setErrors] = useState({
    nameError: '',
    emailError: '',
    messageError: '',
    serverError: '',
  });

    

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <Grid container spacing={2} justifyContent="center" alignItems="center"  sx={{ marginTop: '20px' }} direction="column">
      <Typography variant="h5">Review and Submit</Typography>
      <Typography variant="body1">Quote Ref ID: {uniqueId}</Typography>
    


      
      
          <form id="requestEstimationForm"  encType="multipart/form-data">
          {/* Server Error */}
          {errors.serverError && (
            <Typography variant="body2" color="error">
              {errors.serverError}
            </Typography>
          )}

          {/* Name Field */}
          <TextField
            label="Quote Ref"
            id="quoteRef"
            name="quoteRef"
            fullWidth
            margin="normal"
            value={formData.quoteRef}
            required
            error={!!errors.quoteRefError}
            helperText={errors.quoteRefError}
    InputProps={{
        readOnly: true,
    }}
          />


          {/* Message Field */}
          <TextField
            label="Your Message"
            id="message"
            name="message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={formData.message}
            required
            error={!!errors.messageError}
            helperText={errors.messageError}
  InputProps={{
        readOnly: true,
    }}
          />

          {/* Attachment Field */}
          <Typography variant="body2" gutterBottom>
            Attachment: (Allowed types: .pdf, .dwg, .step, .stl, .iges, .doc, .docx, .png, .jpeg, .ppt, .pptx. Up to 10 MB)
          </Typography>
          <Button variant="outlined" component="label" sx={{ marginBottom: '20px' }}>
            Upload Attachment
            <input
              type="file"
              id="file"
              name="file"
              hidden
              onChange={(e) => setFormData({ ...formData, attachment: e.target.files[0] })}
            />
          </Button>

          {/* Consent */}
          <Typography variant="body2" gutterBottom>
            In order to provide your quote, we need your consent to communicate with you and to store and process your personal data. You can unsubscribe or ask us to remove your data at any time.
          </Typography>


           {/* Search Text Field */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <Typography variant="h6">Selected Partners:</Typography>
      
       {/* DataGrid to display the partners */}
      <Box style={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={filteredPartners}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.partnerId}
        />
      </Box>

 <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Button variant="contained" color="secondary" onClick={handleBack}>
          Back
        </Button>
      </Grid>
      
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>

 

      </form>
    </Grid>
</Box>
</Box>

  );
};

export default ReviewPage;
