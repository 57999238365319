import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, FormControl, InputLabel, Select, TextField, Typography, OutlinedInput, Chip, FormLabel, RadioGroup, FormControlLabel,Radio  } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import VerificationPopup from '../components/VerificationPopup'; 
import axiosInstance from '../common/axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UserDetails = () => {

  const navigate = useNavigate();
    
  const { user , login } = useAuth();
    
const { email, name, givenName, familyName, provider,emailVerified, partnerId } = user || {};

  const [formData, setFormData] = useState({
      emailId:email,
    name: '',
    givenName: '',
    familyName: '',
      provider:provider,
    company: '',
    companyType: '',
    userTypes: [],
    wouldBePartner: 0,
      
  });

  const [companyTypes, setCompanyTypes] = useState([]);
  const [userTypes, setUserTypes] = useState([]);

  // Fetch company types and user types from the API when component mounts
  useEffect(() => {
      console.log("user" + user);
          if (user) {
            setFormData({
                email,
                name,
                givenName,
                familyName,
                provider,
                company: '',
    companyType: '',
    userTypes: [] ,
                wouldBePartner: 0,
            });
        }

    
    
    const fetchTypes = async () => {
      try {
        const companyResponse = await axiosInstance.get(`${backendUrl}/api/company-types`);
          console.log("companyResponse: "+ companyResponse );
          setCompanyTypes(companyResponse.data);
        const userResponse = await axiosInstance.get(`${backendUrl}/api/user-types`);
        setUserTypes(userResponse.data);
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
    fetchTypes();
    }, [user, email, name, givenName, familyName]);
    
    
     const handleRadioChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value, })); 
    };
    

  const handleSubmit = (e) => {
    e.preventDefault();
    const newUser = { ...formData, email };

      if(formData.password != formData.confirmPassword){
         console.log("not equal password ");
          return;
         }
      
      
      axiosInstance.post(`${backendUrl}/api/users`, newUser)
      .then((response) => {
        
        const user = response.data;
        login(user);
        
        if (response.data.userId) {
          // Redirect to dashboard if user exists
             console.log('user:', response.data.userId);
            const userId = response.data.userId;
            const name = response.data.name;
        // Redirect to dashboard after successful form submission
            
            console.log(user.emailVerified);
              console.log("emailVerified"+ emailVerified);
            if(!emailVerified){
                setOpenPopup(true);
               }else{
                navigate('/dashboard');
               }
       
        }
      })
      .catch((error) => {
        console.error('Error saving user:', error);
      });
  };


    const handleUserTypeChange = (event) => {
  const selectedIds = event.target.value;
  setFormData({ ...formData, userTypes: selectedIds });
};
     const [openPopup, setOpenPopup] = useState(false);
    
     const handleClosePopup = () => {
         
    setOpenPopup(false);
  };

    
    
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 4,
        backgroundColor: '#f4f6f8'
      }}
    >
      <Typography variant="h4" gutterBottom>
        User Details Form
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 600,
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3
        }}
      >
      
      <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={formData.emailId}
                    onChange={(e) => setFormData({ ...formData, emailId: e.target.value })}
                     required
   InputProps={{
        readOnly: !formData.emailId || formData.emailId.trim() === "",
    }}
                />
                        
        {/* Name Field */}
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          margin="normal"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />

        {/* Given Name Field */}
        <TextField
          fullWidth
          label="Given Name"
          variant="outlined"
          margin="normal"
          value={formData.givenName}
          onChange={(e) => setFormData({ ...formData, givenName: e.target.value })}
          required
        />

        {/* Family Name Field */}
        <TextField
          fullWidth
          label="Family Name"
          variant="outlined"
          margin="normal"
          value={formData.familyName}
          onChange={(e) => setFormData({ ...formData, familyName: e.target.value })}
          required
        />

        {/* Company Field */}
        <TextField
          fullWidth
          label="Company"
          variant="outlined"
          margin="normal"
          value={formData.company}
          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
          required
        />
              

              
   <FormControl fullWidth variant="outlined" margin="normal">
  <InputLabel id="companyType-label">Company Type</InputLabel>
  <Select
    labelId="companyType-label"
    id="companyType"
    value={formData.companyType?.typeName || ""}
    onChange={(e) => {
      const selectedType = companyTypes.find((type) => type.typeName === e.target.value);
      setFormData({ ...formData, companyType: selectedType });
    }}
    label="Company Type"
    required
  >
    <MenuItem value="">
      <em>Select Company Type</em>
    </MenuItem>
    {companyTypes.map((type) => (
      <MenuItem key={type.id} value={type.typeName}>
        {type.typeName}
      </MenuItem>
    ))}
  </Select>
</FormControl>



    {/* User Type Field (Multiple Select) */}
<FormControl fullWidth variant="outlined" margin="normal">
  <InputLabel id="userType-label">User Type</InputLabel>
  <Select
    labelId="userType-label"
    id="userType"
    multiple
    value={formData.userTypes}  // Array of selected type IDs
    onChange={handleUserTypeChange}  // Updated handler for storing IDs
    required
    input={<OutlinedInput id="select-multiple-chip" label="User Type" />}
    renderValue={(selected) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {/* Map over selected IDs and display their names */}
        {selected.map((userTypeId) => {
          const selectedType = userTypes.find((type) => type.userTypeId === userTypeId);
          return <Chip key={userTypeId} label={selectedType?.typeName} />;
        })}
      </Box>
    )}
  >
    {userTypes.map((type) => (
      <MenuItem key={type.userTypeId} value={type.userTypeId}>
        {type.typeName}
      </MenuItem>
    ))}
  </Select>
</FormControl>


  <FormControl component="fieldset">
    <FormLabel component="legend">Would you like to become partner with us? </FormLabel>
     <RadioGroup aria-label="wouldBePartner" name="wouldBePartner" value={formData.wouldBePartner} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>


 {(provider === '' || provider === null ) && (
          <>
     <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          required
        />
              
              <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          variant="outlined"
          margin="normal"
          value={formData.confirmPassword}
          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
          required
        />
            
       
          </>
)}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>

   <VerificationPopup
        open={openPopup}
        handleClose={handleClosePopup}
      />
            
            
      </Box>
    </Box>
  );
};

export default UserDetails;
