import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box , TextField, Button, Grid, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../common/axios';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate  } from 'react-router-dom'; 




const PartGroupForm = () => {
    
              const navigate = useNavigate(); 
      const { user } = useAuth();
        const { email, name, userId, roles, userTypes} =  user || {};
    
      const [categoryId, setCategoryId] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState([]);
    
  const [formData, setFormData] = useState({
    groupName: '',
    description: '',
    categoryId: '',
    materialTypeId: '',
    dimensions: '',
    weight: '',
  });
    
    
      // Fetch company types and user types from the API when component mounts
  useEffect(() => {

    
    
    const fetchTypes = async () => {
      try {
        const industryResponse = await axiosInstance.get(`/api/industry-category`);
          console.log("companyResponse: "+ industryResponse );
          setCategoryId(industryResponse.data);
        const materialResponse = await axiosInstance.get(`/api/material-type`);
        setMaterialTypeId(materialResponse.data);
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
    fetchTypes();
    }, [user, email, name, ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/api/part-group', formData);
      alert("Part group saved successfully!");
      setFormData({
        groupName: '',
        description: '',
        category: '',
        materialTypeId: '',
        dimensions: '',
        weight: '',
      });
    } catch (error) {
      console.error("Error saving part group:", error);
      alert("Failed to save part group. Please try again.");
    }
  };

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Create Part Group
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Group Name"
            name="groupName"
            value={formData.groupName}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              name="categoryId"
              value={formData.categoryId}
              onChange={handleChange}
              required
            >
             {categoryId.map((type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.categoryName}
      </MenuItem>
          ))}

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Material Type</InputLabel>
            <Select
              name="materialTypeId"
              value={formData.materialTypeId}
              onChange={handleChange}
            >
       {materialTypeId.map((type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.typeName}
      </MenuItem>
    ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Dimensions"
            name="dimensions"
            value={formData.dimensions}
            onChange={handleChange}
            placeholder="e.g., 10x10x10 cm"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Weight"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            placeholder="e.g., 1.5 kg"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Save Part Group
          </Button>
        </Grid>
      </Grid>
    </form>
      </Box>
      </Box>
  );
};

export default PartGroupForm;
