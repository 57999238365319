import React, { useState, useEffect } from 'react';
import Hero from './pages/Hero';
import { BrowserRouter as Router, Route, Routes,  Navigate, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import FooterShort from './components/FooterShort';
// Import your other components/pages
import AboutUs from './pages/AboutUs';
import CapabilityCapacity from './pages/CapabilityCapacity';
import FieldWeServe from './pages/FieldWeServe';
import QualityCertifications from './pages/QualityCertifications';
import ManufacturingLocations from './pages/ManufacturingLocations';
import ManufacturingConsulting from './pages/ManufacturingConsulting';
import SupplyMarketDevelopment from './pages/SupplyMarketDevelopment';
import SupplyChainManagement from './pages/SupplyChainManagement';
import OnTimeDelivery from './pages/OnTimeDelivery';
import QualityControl from './pages/QualityControl';
import SourceEngagement from './pages/SourceEngagement';
import ExpertLogisticsManagement from './pages/ExpertLogisticsManagement';
import CustomerTransparencyVisibility from './pages/CustomerTransparencyVisibility';
import SSOLogin from './auth/SSOLogin';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Dashboard from './auth/Dashboard';
import QuotePage from './auth/QuotePage';
import QuoteListPage from './auth/QuoteListPage';
import OrdersPage from './auth/OrdersPage';
import TrackerPage from './auth/TrackerPage';
import UserDetails from './auth/UserDetails';
import Profile from './auth/Profile';
import GoodReceiptNote from './auth/GoodReceiptNote';

import InternalQuote from './auth/InternalQuote';
import SelectUsersPage from './auth/SelectUsersPage';
import ReviewPage from './auth/ReviewPage';

import ManufacturingRegistration from './auth/ManufacturingReg';
import SupplierRegistration from './auth/SupplierReg';
import DealerRegistration from './auth/DealerReg';
import Dealers from './auth/Dealers';
import Suppliers from './auth/Suppliers';
import Manufacturers from './auth/Manufacturers';
import PurchaseOrder from './auth/PurchaseOrder';
import Invoice from './auth/Invoice';
import Partner from './auth/Partner';
import ReEstimationView from './auth/ReEstimationView';
import EstimationView from './auth/EstimationView'; 
import ReEstimation from './auth/ReEstimation';
import Trader from './auth/Trader'; 
import Traders from './auth/Traders'; 
import BuilderInfo from './auth/BuilderInfo'; 
import Builders from './auth/Builders'; 
import PartnerView from './auth/PartnerView';
import Report from './auth/Report';
import PartGroupForm from './auth/PartGroupForm';
import PartForm from './auth/PartForm';
import PurchaseOrderList from './auth/PurchaseOrderList';
import InvoiceView from './auth/InvoiceView';


import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';

import FAQ from './pages/FAQ';
import ContactUs from './pages/ContactUs';
import Refund from './pages/Refund';
import Chatbot from './components/Chatbot';
import Menu from './components/Menu';
import Header from './components/Header';
import HeaderShort from './components/HeaderShort';
import { useAuth } from './context/AuthContext';

import Login from './auth/Login';
import axios from 'axios';  


function App() {
       const navigate = useNavigate(); 
  const { user, logout, setUser  } = useAuth(); // Get user and logout from AuthContext

    // Define the handleLoginSuccess function
  const handleLoginSuccess = (userData) => {
    setUser(userData); // Update the user state in the AuthContext
   // navigate('/dashboard'); // Redirect to the dashboard after successful login
  };
    
  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
        navigate('/login'); 
  };

    
     useEffect(() => {
    // Add interceptor on app initialization
    axios.interceptors.request.use(config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });
  }, []); // Only run this on app initialization


    
    
  return (



     <div>
      {/* Conditional rendering based on user authentication status */}
      {user ? <HeaderShort onLogout={handleLogout} /> : <Header />}
      {user ? <div /> : <Menu />}
      
    
      <Routes>
           <Route path="/" element={<Hero />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/capability-capacity" element={<CapabilityCapacity />} />
        <Route path="/field-we-serve" element={<FieldWeServe />} />
        <Route path="/quality-certifications" element={<QualityCertifications />} />
        <Route path="/manufacturing-locations" element={<ManufacturingLocations />} />
        <Route path="/manufacturing-consulting" element={<ManufacturingConsulting />} />
        <Route path="/supply-market-development" element={<SupplyMarketDevelopment />} />
        <Route path="/supply-chain" element={<SupplyChainManagement />} />
      <Route path="/expert-logistic" element={<ExpertLogisticsManagement />} />
      <Route path="/source-engagement" element={<SourceEngagement />} />
      <Route path="/customer-transparency" element={<CustomerTransparencyVisibility />} />
        <Route path="/ontime-delivery" element={<OnTimeDelivery />} />
        <Route path="/quality-control" element={<QualityControl />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/quote" element={<Hero />} />
        <Route path="/terms" element={<Terms />} />
         <Route path="/privacypolicy" element={<Privacy />} />
              <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
          {user ? (
          <Route path="/dashboard" element={<Dashboard />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
         <Route path="/profile" element={<Profile />} />  
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
         <Route path="/request-quote" element={<QuotePage />} />  
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
         <Route path="/quote-list" element={<QuoteListPage />} /> 
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
            <Route path="/orders" element={<OrdersPage />} /> 
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/tracker" element={<TrackerPage />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/dealerreg" element={<DealerRegistration />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
             <Route path="/manufacturerreg" element={<ManufacturingRegistration />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
             <Route path="/suppliereg" element={<SupplierRegistration />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
             <Route path="/purchaseorder" element={<PurchaseOrder />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}

 {user ? (
             <Route path="/invoice" element={<Invoice />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/user-details" element={<UserDetails />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/dealers" element={<Dealers />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/suppliers" element={<Suppliers />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/manufacturers" element={<Manufacturers />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}



 {user ? (
             <Route path="/send-estimate" element={<InternalQuote />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/select-users" element={<SelectUsersPage />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/review" element={<ReviewPage />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/partner" element={<Partner />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/submit-estimation" element={<ReEstimation />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/estimation-details" element={<ReEstimationView />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}

 {user ? (
             <Route path="/estimate" element={<EstimationView />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/trader" element={<Trader />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/traders" element={<Traders />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}

 {user ? (
             <Route path="/builder" element={<BuilderInfo />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}

 {user ? (
             <Route path="/builders" element={<Builders />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}

 {user ? (
             <Route path="/partnerview" element={<PartnerView />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/report" element={<Report />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/parts" element={<PartForm />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/partgroup" element={<PartGroupForm />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> 
        )}


 {user ? (
             <Route path="/invoiceview" element={<InvoiceView />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? (
             <Route path="/purchaseorderlist" element={<PurchaseOrderList />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


 {user ? ( 
             <Route path="/grn" element={<GoodReceiptNote />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect if not logged in
        )}


      </Routes>
       {user ? <div></div> :   <Chatbot />}
       {user ? <FooterShort /> :  <Footer />}
  </div> 
  );
}

export default App;
