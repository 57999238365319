import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ApproveConfirmationDialog = ({ open, handleClose, handleConfirm, type }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Confirm ${type}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to {type} this quote?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">Cancel</Button>
      <Button onClick={handleConfirm} color="primary">{type}</Button>
    </DialogActions>
  </Dialog>
);

export default ApproveConfirmationDialog;
