import React, { useState, useEffect } from 'react';
import './Hero.css';
import { Box, Button, Typography, Modal, FormGroup, FormHelperText } from '@mui/material';
import {countryCodes} from '../common/countries';
import { useDropzone } from 'react-dropzone';

 const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Hero() {
  const images = [
    '../images/hero_mc.jpeg',
    'images/transportation.jpg',
    'images/medical_1.jpg',
    'images/industry.jpg',
    'images/construction.jpg',
    'images/energy.jpg',
    'images/food.jpg'
  ];

  const headers = [
    'Welcome to Our Website',
    'Field We Serve - Transportation',
    'Field We Serve - Medical',
    'Field We Serve - Industry',
    'Field We Serve - Construction',
    'Field We Serve - Energy',
    'Field We Serve - Food '
  ];

  const contents = [
    'Ours is a global manufacturing network with interconnected functions, operations, and transactions to bring a product from prototype to final delivery with complex supply chain management for the on-demand manufacturing model.',
    '',
    '',
    '',
    '',
    '', /* <a href="https://www.freepik.com/free-photo/3d-solar-pannels-project-energy-saving_13328762.htm#fromView=search&page=1&position=0&uuid=eccef984-ac59-4f8a-8ca8-ca387e9ea9c2">Image by freepik</a>', */
    ''
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    
       const [attachment, setAttachment] = useState(null);   

  useEffect(() => {
    const slideshowInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(slideshowInterval); // Cleanup interval on component unmount
  }, [images.length]);
    
    
    
        // Handle file upload via button or dropzone
  const handleFileChange = (e) => {
     const file = e.target.files[0];
    setAttachment(file);
  };

    
  const handleDropFileChange = (file) => {
    setAttachment(file);
  };
    
  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleDropFileChange(acceptedFiles[0]);
    },
    accept: {
      'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-pki.stl': ['.stl'],
  'application/vnd.ms-package.3dxml': ['.3dxml'],
  'application/octet-stream': ['.dwg', '.step', '.stp', '.sldprt', '.dxf', '.ipt', '.x_t', '.x_b', '.catpart', '.prt', '.sat', '.3mf', '.jt', '.iges'],
    },
    maxSize: 10 * 1024 * 1024, // 10 MB
  });
    

  const submitForm = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(document.getElementById("requestQuoteForm"));
    
    // Validate form fields
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
       const countryCode = document.getElementById("countryCode").value;
      
      const checkbox = document.getElementById('dataPrivacy'); 
      
 
    
    // Reset previous error messages
    const errorElements = document.getElementsByClassName('error');
    for (let i = 0; i < errorElements.length; i++) {
        errorElements[i].innerHTML = '';
    }

    if (!name) {
        document.getElementById('nameError').innerHTML = 'Name is required';
        return;
    }

    if (!email || !isValidEmail(email)) {
        document.getElementById('emailError').innerHTML = 'Invalid Email ID';
        return;
    }
    
    if (!phoneNumber) {
        document.getElementById('phoneNumberError').innerHTML = 'Enter the phone number';
        return;
    }
      
          if (!countryCode) {
        document.getElementById('phoneNumberError').innerHTML = 'Select the Country Code';
        return;
    }
      
    
    if (!message) {
        document.getElementById('messageError').innerHTML = 'Message is required';
        return;
    }
  
      if (!checkbox.checked) 
      { 
       document.getElementById('dataPrivacyError').innerHTML = 'Please accept data privacy terms.';
          return;
     } 
    
     formData.append("dataPrivacy", true);

        if(formData.attachment){
            formData.append("fileName", attachment.name);
            formData.append('attachment', attachment, attachment.name );
        }
      
    fetch(`${backendUrl}/api/quote-request`, {
        method: 'POST',
        body: formData
    })
    .then(response =>  {
        if (response.ok) {
            alert("Thank you for Requesting a Quote!! We will get back to you ASAP.");
            document.getElementById("requestQuoteForm").reset();
            return;
        }
        return response.json();
        
    }).then(data => {
        if(data){
            if(typeof data.customMessage !== 'undefined'){
                document.getElementById('rserverError').innerHTML = data.customMessage;
            }
        }
    })
    .catch(error => {
        console.error('Error submitting the form:', error);
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="container">
      <div
        className={`hero ${!open ? 'hero-full-height' : ''}`} 
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      >
        <div className="hero-content">
          <h1 id="hero-content-header">{headers[currentImageIndex]}</h1>
          <p id="hero-content-p">{contents[currentImageIndex]}</p>
        </div>
{!open && (
       <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{ backgroundColor: '#941B0C' }} // Adjust the button color as needed
      >
        Request Quote
      </Button>
)}
{open && (
        <div className="quote-form" id="quote">

          <h2>Request a Quote</h2>
          <form id="requestQuoteForm" enctype="multipart/form-data">
            <div id="rserverError" className="error"></div>
            <div id="nameError" className="error"></div>
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" required />
            <div id="emailError" className="error"></div>
            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" name="email" required />


            <div id="phoneNumberError" className="error"></div>
            <label htmlFor="phoneNumber">Your Phone Number </label>
            <div className="phoneNumberContainer">
              <select
                id="countryCode"
                name="countryCode"
                required
              >
                <option value="">Select Country Code</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.code} ({country.country})
                  </option>
                ))}
              </select>

              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                required
              />
            </div>


            <div id="messageError" className="error"></div>
            <label htmlFor="message">Describe your Work</label>
            <textarea id="message" name="message" rows="4" required></textarea>


 {/* Attachment Field */}
      <Typography variant="body2" sx={{ color: '#ffffff' }} gutterBottom>
        Attachment: (Allowed types: .pdf, .dwg, .step, .stp, .sldprt, .dxf, .ipt, .x_t, .x_b, .catpart, .prt, .sat, .3mf, .jt, .iges, .doc, .docx, .png, .jpeg, .jpg, .ppt, .pptx, .txt, .3dxml Up to 10 MB)
      </Typography>

      {/* Dropzone Area */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2" sx={{ color: '#ffffff' }}>Drop the files here ...</Typography>
        ) : (
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        )}
      </Box>

      {/* Upload Button */}
      <Button variant="outlined" component="label" sx={{ marginBottom: '20px' ,   backgroundColor: '#226F54' , 
  color: '#ffffff' }}>
        Upload Attachment
        <input
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {/* Show Uploaded File */}
      {attachment && (
        <Typography variant="body2" sx={{ marginTop: '10px' , color: '#ffffff'}}>
          Uploaded file: {attachment.name}
        </Typography>
      )}
            
              <Typography variant="body2" sx={{ color: '#ffffff' }} gutterBottom>
In order to provide your quote, we need your consent to communicate with you and to store and process your personal data. If you consent, please check the boxes below. You can unsubscribe or ask us to remove your data at any time. </Typography>


<div> <label> <input type="checkbox" id="dataPrivacy" required /> I accept the data privacy terms </label> <span id="dataPrivacyError" class="error"></span> </div>

            <button type="button" onClick={submitForm}>Submit</button>
          </form>
        </div>
)}
      </div>
    </div>
  );
}

export default Hero;
