import React, { useState } from 'react';
import {  AppBar, Toolbar,
  TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography,
  Divider, Box, RadioGroup, FormControlLabel, Radio, TextareaAutosize
} from '@mui/material';
import html2canvas from 'html2canvas';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate  } from 'react-router-dom';  // To get user email from login
import axiosInstance from '../common/axios';
import LeftDrawer from '../components/LeftDrawer';
import TermsDropdownViewer from "./TermsDropdownViewer";
import { PDFDocument } from "pdf-lib";
import { jsPDF } from "jspdf";
import { getDocument } from "pdfjs-dist";

import { PDFViewer, Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: { flexDirection: "column", padding: 20 },
  section: { margin: 10, padding: 10 },
  header: { fontSize: 18, textAlign: "center", marginBottom: 10 },
});
  

 const backendUrl = process.env.REACT_APP_BACKEND_URL;


const PurchaseOrder = () => {
    
    
      const { user } = useAuth();
    
    const { email, name, userId, roles, userTypes} =  user || {};
 const navigate = useNavigate(); 
    
        const termsTxt = "Unless explicitly stated in this Purchase Order (PO) or agreed otherwise in writing, our prevailing General Terms and Conditions (GTC) will apply. The GTC can be downloaded from https://cosmicwebwork.com.com or provided upon request. The acceptance of this PO constitutes a legally binding and effective contract between the parties. By accepting or acknowledging this PO, either explicitly or implicitly, you confirm that you have read and are satisfied with the GTC's accuracy and adequacy regarding the risks, liabilities, and obligations outlined, thereby concluding a legally binding contract between both parties.<br>The Customer (or Purchaser) reserves the right to cancel the PO if the Supplier does not confirm its acceptance in writing within two weeks of receipt. Any deviation from the PO terms requires the Purchaser's written approval to be binding. Only deliveries and services based on a PO will be acknowledged. Any changes or additions to the PO must be confirmed in writing by the Customer to be effective. Acceptance of deliveries or services does not imply approval. This PO must not be used for reference, advertisement, or similar purposes without explicit permission.";
    
    
    
  const [formData, setFormData] = useState({
    vendor: '',
    vendorAddress: '',
    shipTo: '',
    shipToAddress: '',
         vendorContactName: '',
    vendorContactNumber: '',
    shipToContactName: '',
    shipToContactNumber: '',
    phone: '',
    poNumber: '',
    orderDate: '',
    items: [
      { qty: '', item: '', description: '', unitPrice: '', total: '' },
    ],
    shippingService: '',
    shippingMethod: '',
    deliveryDate: '',
    subtotal: 0,
    salesTax: 0,
    shippingCharge: 0,
    grandTotal: 0,
    vendorSignature: '',
    vendorSignatureDate: '',
    customerSignature: '',
    customerSignatureDate: '',
          country: 'international', // Default country selection is 'international'
    terms:termsTxt ,
      quoteorderUniqueId: '',
      estimationUniqueId: '',
      poUniqueId:'',
      term:'',
      
  });
    
      const [selectedTermId, setSelectedTermId] = useState(null);


  const handleTermSelect = (termId) => {
    setSelectedTermId(termId);
    console.log("Selected Term ID:", termId);
    // Perform additional actions with termId if needed

      
  };
    
    

    

    const savePurchaseOrder = async () => {

  //  const response = axiosInstance.post('/api/purchase-orders', JSON.stringify(formData))
    
    axiosInstance.post('/api/purchase-orders', formData, {
  headers: {
    'Content-Type': 'application/json',
  }
})
.then(response => {
  console.log('Order saved:', response.data);
})
.catch(error => {
  console.error('Error saving order:', error);
});
      
    

};
    
     
    
     const [poSearch, setPoSearch] = useState(""); // State for the PO search input
  const [poList, setPoList] = useState([]); // State for the PO list
  const [showPopup, setShowPopup] = useState(false); // State to toggle the PO popup
    

    const [partnerSearch, setPartnerSearch] = useState(""); // Input for partner unique ID 
  const [showPartnerPopup, setShowPartnerPopup] = useState(false); // Toggle partner popup
  const [partnerList, setPartnerList] = useState([]); // List of partners fetched

        const [estimationSearch, setEstimationSearch] = useState(""); // Input for partner unique ID 
  const [showEstimationPopup, setShowEstimationPopup] = useState(false); // Toggle partner popup
  const [estimationList, setEstimationList] = useState([]); // List of partners fetched

    
  // Fetch PO list based on the search
  const searchEstimation = async () => {

    // Replace this with your actual API endpoint
    const response = await axiosInstance.get(`${backendUrl}/api/estimations/search?prefix=${estimationSearch}`);
      
    setEstimationList(response.data); // Assume the API returns a list of POs
      setShowEstimationPopup(true)
  };
   
    
    
  // Fetch PO list based on the search
  const searchPartner = async () => {
      
    // Replace this with your actual API endpoint
    const response = await axiosInstance.get(`${backendUrl}/api/partners/search?prefix=${partnerSearch}`);
      
    setPartnerList(response.data); // Assume the API returns a list of POs
      setShowPartnerPopup(true)
  };
   

  // Fetch PO list based on the search
  const searchPO = async () => {
    // Replace this with your actual API endpoint
    const response =  await axiosInstance.get(`${backendUrl}/api/purchase-orders/search?prefix=${poSearch}`);
   
    setPoList(response.data); // Assume the API returns a list of POs
    setShowPopup(true); // Show the popup with results
  };
    
   
        const selectEstimation = async (estimation) => {
         
          
    const response = await axiosInstance.get(`/api/partners/${estimation.partnerId}`);
    const data = await response.data;

    // Fill the formData with the details from the API
    setFormData({
      ...formData,
    vendor: data.companyName ,
    vendorAddress: data.billingAddress.streetAddressLine1 + ' ' + data.billingAddress.streetAddressLine2 +' '+ data.billingAddress.city + ' ' + data.billingAddress.state + ' '+ data.billingAddress.zipCode + ' ' + data.billingAddress.country,
         vendorContactName:  data.altPrimaryName,
    vendorContactNumber:  data.altPrimaryCountryCode +  data.altPrimaryPhoneNumber ,
        emailId: data.busEmailId ,
        phone:data.busCountryCode + data.busPhoneNumber, 
        vendorCountry: data.country,
        businessLicenseNumber: data.businessLicenseNumber,
        taxIdentificationNumber: data.taxIdentificationNumber,
              quoteorderUniqueId:estimation.quoteRef,
      estimationUniqueId:estimation.uniqueId,
    });
             setShowEstimationPopup(false);

        }
    
    

      const selectPartner = async (partnerId) => {
 
    const response = await axiosInstance.get(`/api/partners/${partnerId}`);
    const data = await response.data;

    // Fill the formData with the details from the API
    setFormData({
      ...formData,
    vendor: data.companyName ,
    vendorAddress: data.billingAddress.streetAddressLine1 + ' ' + data.billingAddress.streetAddressLine2 +' '+ data.billingAddress.city + ' ' + data.billingAddress.state + ' '+ data.billingAddress.zipCode + ' ' + data.billingAddress.country,
         vendorContactName:  data.altPrimaryName,
    vendorContactNumber:  data.altPrimaryCountryCode +  data.altPrimaryPhoneNumber ,
        emailId: data.busEmailId ,
        phone:data.busCountryCode + data.busPhoneNumber, 
        vendorCountry: data.country,
        businessLicenseNumber: data.businessLicenseNumber,
        taxIdentificationNumber: data.taxIdentificationNumber,
    });

    setShowPartnerPopup(false); // Close the popup

};   
    
    
        

          

  // Fetch PO details when a PO is selected
  const selectPO = async (poNumber) => {
    // Replace this with your actual API endpoint for fetching PO details
    const response = await axiosInstance.get(`/api/purchase-orders/${poNumber}`);
   const data = await response.data;
      


      setFormData({
        vendor: data.vendor,
        vendorAddress: data.vendorAddress || '',
        shipTo: data.shipTo || '',
        shipToAddress: data.shipToAddress || '',
        vendorContactName: data.vendorContactName || '',
        vendorContactNumber: data.vendorContactNumber || '',
        shipToContactName: data.shipToContactName || '',
        shipToContactNumber: data.shipToContactNumber || '',
        phone: data.phone || '',
        poNumber: data.poNumber || '',
        orderDate: data.orderDate || '',
        items: data.items.map(item => ({
          qty: item.qty || '',
          item: item.item || '',
          description: item.description || '',
          unitPrice: item.unitPrice || '',
          total: item.total || ''
        })) || [{ qty: '', item: '', description: '', unitPrice: '', total: '' }],
        shippingService: data.shippingService || '',
        shippingMethod: data.shippingMethod || '',
        deliveryDate: data.deliveryDate || '',
        subtotal: data.subtotal || 0,
          saleTaxPercentage: data.saleTaxPercentage || 0,
        salesTax: data.salesTax || 0,
        shippingCharge: data.shippingCharge || 0,
        grandTotal: data.grandTotal || 0,
        vendorSignature: data.vendorSignature || '',
        vendorSignatureDate: data.vendorSignatureDate || '',
        customerSignature: data.customerSignature || '',
        customerSignatureDate: data.customerSignatureDate || '',
        country: data.country || '',
        terms: termsTxt || '',
        quoteorderUniqueId: data.quoteorderUniqueId || '',
        estimationUniqueId: data.estimationUniqueId || '',
                emailId: data.emailId ,
        phone:data.phone, 
        vendorCountry: data.vendorCountry,
        businessLicenseNumber: data.businessLicenseNumber,
        taxIdentificationNumber: data.taxIdentificationNumber,
          shipToCountry: data.shipToCountry,
          poUniqueId: data.poUniqueId,
      });




    setShowPopup(false); // Close the popup
  };
    
    

    
    

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 /* const handleItemChange = (index, e) => {
    const updatedItems = [...formData.items];
    updatedItems[index][e.target.name] = e.target.value;
    setFormData({ ...formData, items: updatedItems });
      
      formData.items.map((item, index) => {
          
                console.log(`unit: ${item.qty}`);  
              console.log(`unit: ${item.unitPrice}`);  
      
        console.log(`total: ${item.total}`);  
          
      });
  };*/
    
    

  const addItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { qty: '', item: '', description: '', unitPrice: '', total: '' }],
    });
  };

    
  const handleItemChange = (index, e) => { 
      const { name, value } = e.target; 
      const newItems = [...formData.items]; 
      newItems[index][name] = value; newItems[index].total = (newItems[index].qty * newItems[index].unitPrice).toFixed(2);
      
      setFormData({ ...formData, items: newItems }); 
      calculateTotal(newItems); 
  }; 
    
    const calculateTotal = (items) => {
        const subtotal = formData.items.reduce((acc, item) => acc + (parseFloat(item.total) || 0), 0); 
        const saleTaxPercentage = parseFloat(formData.saleTaxPercentage) || 0;
         let salesTax = parseFloat(formData.salesTax) || 0;
      if (!isNaN(saleTaxPercentage)) { 
        salesTax = (subtotal * saleTaxPercentage) / 100;
       }
      
        const grandTotal = (subtotal + parseFloat(salesTax) + parseFloat(formData.shippingCharge) || 0 ); 
        
        setFormData((prevFormData) => ({ ...prevFormData, subtotal: isNaN(salesTax) ? '0.00' : subtotal, salesTax: isNaN(salesTax) ? '0.00' : salesTax, grandTotal: isNaN(grandTotal) ? '0.00' : grandTotal,
})); 
};
        
    
    // Convert PDF blob to base64 (for image conversion or embedding)
   const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob); // Converts blob to Base64
  });
};
    
    

async function renderPDFToImage(pdfData) {
  const pdf = await getDocument({ data: pdfData }).promise;
  const page = await pdf.getPage(1); // Get the first page
  const viewport = page.getViewport({ scale: 2 });

  // Create canvas to render
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = viewport.width;
  canvas.height = viewport.height;

  await page.render({
    canvasContext: context,
    viewport,
  }).promise;

  // Convert canvas to Base64
  return canvas.toDataURL("image/png");
}

 

  const exportPDF = () => {
      
            
            
    const input = document.getElementById('po-form');
    html2canvas(input, { scale: 2, useCORS: true, width: input.scrollWidth }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgWidth = 595; // A4 width in pt
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.height;

      // Add more pages if necessary
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.height;
      }

      pdf.save('purchase_order.pdf');
    });
  }; 
    
      const handleCountryChange = (e) => {
    setFormData({ ...formData, country: e.target.value });
  };

    
     const handleShipToChange = (e) => {
         if( e.target.value === 'india'){
         setFormData({ ...formData, shipToAddress: 'india address ' ,
                      shipToCountry: 'India '
                     , shipTo: 'CosmicWebWork India ' });
         }else{
                setFormData({ ...formData, shipToAddress: '5109 W 162nd St, Overland Park Kanasas, USA 66085 ', 
                             shipToCountry: 'usa ',
                             shipTo: 'CosmicWebWork USA '  });

         }
        
     }
    

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>

    <div style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
      
        
      {/* Partner Search Form */}
      <div className="section">
        <div className="form-group">
          <label>Search Partner Unique ID:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={partnerSearch}
              onChange={(e) => setPartnerSearch(e.target.value)}
              placeholder="Enter Partner Unique ID"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchPartner}>Search</button>
          </div>
        </div>
      </div>

      {/* Popup Modal for Partner Selection */}
      {showPartnerPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select Partner</h3>
            <ul>
              {partnerList.map((partner) => (
                <li
                  key={partner.partnerId}
                  onClick={() => selectPartner(partner.partnerId)}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                >
                  {partner.partnerId} - {partner.companyName}
                </li>
              ))}
            </ul>
            <button onClick={() => setShowPartnerPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
            
      {/* Estimation Search Form */}
      <div className="section">
        <div className="form-group">
          <label>Search Estimation Unique ID:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={estimationSearch}
              onChange={(e) => setEstimationSearch(e.target.value)}
              placeholder="Enter Estimation Unique ID"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchEstimation}>Search</button>
          </div>
        </div>
      </div>

      {/* Popup Modal for Estimation Selection */}
      {showEstimationPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select Partner</h3>
            <ul>
              {estimationList.map((estimation) => (
                <li
                  key={estimation.partnerId}
                  onClick={() => selectEstimation(estimation)}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                >
                  {estimation.partnerId} - {estimation.uniqueId}
                </li>
              ))}
            </ul>
            <button onClick={() => showEstimationPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
      

        <div className="form-group">
          <label>Search PO Number:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={poSearch}
              onChange={(e) => setPoSearch(e.target.value)}
              placeholder="Enter PO Number"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchPO}>Search</button>
          </div>
        </div>
  
      {/* Popup Modal for PO Selection */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select PO Number</h3>
            <ul>
              {poList.map((po) => (
                <li key={po.poUniqueId} onClick={() => selectPO(po.id)}>
                  {po.poUniqueId} - {po.poStatus}
                </li>
              ))}
            </ul>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
        {/* Country Selection */}
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Ship To Country</Typography>
        <RadioGroup row name="ShipToOptioncountry" onChange={handleShipToChange}>
          <FormControlLabel value="usa" control={<Radio />} label="CosmicWebWork USA" />
          <FormControlLabel value="india" control={<Radio />} label="CosmicWebWork India" />
        </RadioGroup>


    <div>
      <TermsDropdownViewer onTermSelect={handleTermSelect} />
    </div>
    
        {/* Export Button */}
        <Box textAlign="center" mt={4}>
      
       <Button variant="contained" color="secondary" onClick={savePurchaseOrder}>
            Submit Purchase Order
          </Button>

          <Button variant="contained" color="primary" onClick={exportPDF}>Export Your Purchase Order</Button>
                 <TextField fullWidth label="EstimationUniqueId" name="estimationUniqueId" value={formData.estimationUniqueId} onChange={handleInputChange} />
            <TextField fullWidth label="Quote OrderUniqueId" name="quoteOrderUniqueId" value={formData.quoteorderUniqueId} onChange={handleInputChange} />
   </Box>
      
      <div id="po-form" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', background: '#fff', border: '1px solid #ddd' }}>
           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <img src="../images/cosmicwebwork_trim.png" alt="Company Logo" style={{ width: '150px', marginRight: '400px' }} />
            
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Purchase Order
          </Typography>
      
        </div>
          <Typography variant="h6"style={{ fontWeight: 'bold', fontSize: '12px', width: '150px', marginRight: '400px' }}>
            CosmicWebWork LLC 
          5109 W 162nd St, 
             Overland Park
            Kanasas, USA 66085
      
      </Typography>
      
      <Divider style={{ margin: '20px 0' }} />
 
      
        {/* Vendor and Shipping Section */}
        <Grid container spacing={2}>
       
        
          <Grid item xs={6}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Vendor</Typography>
            <TextField fullWidth label="Vendor" name="vendor" value={formData.vendor} onChange={handleInputChange} />
      <TextField fullWidth label="Vendor Address" name="vendorAddress" value={formData.vendorAddress} onChange={handleInputChange} multiline rows={4}/>
       <TextField fullWidth label="Country" name="vendorCountry" value={formData.vendorCountry} onChange={handleInputChange} />
      
      <TextField fullWidth label="EmailId" name="emailId" value={formData.emailId} onChange={handleInputChange} />
            <TextField fullWidth label="Phone" name="phone" value={formData.phone} onChange={handleInputChange} />
     <TextField fullWidth label="Business LicenseNumber" name="businessLicenseNumber" value={formData.businessLicenseNumber} onChange={handleInputChange} />
       <TextField fullWidth label="Tax IdentificationNumber" name="taxIdentificationNumber" value={formData.taxIdentificationNumber} onChange={handleInputChange} />
          
          </Grid>
          <Grid item xs={6}>
       <Typography variant="h16"style={{ fontWeight: 'bold', fontSize: '12px', margin: '0px 0px' }}> This Purchase Order ("PO") is subject to the
agreed terms and conditions. All goods or
services shall be delivered in accordance.
In order to comply with international export
regulations, wherever applicable, every invoice
shall state related ECCN or AL number for the
good(s) or product(s) supplied.  </Typography>
      
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Ship To</Typography>
            <TextField fullWidth label="Ship To" name="shipTo" value={formData.shipTo} onChange={handleInputChange} />
      <TextField fullWidth label="ShipTo Address" name="shipToAddress" value={formData.shipToAddress} onChange={handleInputChange} multiline rows={4}/>
       <TextField fullWidth label="Country" name="shipToCountry" value={formData.shipToCountry} onChange={handleInputChange} />
          </Grid>
        </Grid>

      <Divider style={{ margin: '20px 0' }} />
    
      
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <TextField fullWidth label="PO Number" name="poUniqueId" value={formData.poUniqueId} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Order Date" name="orderDate" type="date" value={formData.orderDate} onChange={handleInputChange} />
          </Grid>
        </Grid>
      
      <Divider style={{ margin: '20px 0' }} />
         <Typography variant="h6" style={{ fontWeight: 'bold' }}>Contact Details </Typography>
             <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
             <TextField fullWidth label="Vendor Contact Name" name="vendorContactName" value={formData.vendorContactName} onChange={handleInputChange} />
            <TextField fullWidth label="Vendor Contact Number" name="vendorContactNumber" value={formData.vendorContactNumber} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
              <TextField fullWidth label="Ship To Contact Name" name="shipToContactName" value={formData.shipToContactName} onChange={handleInputChange} />
            <TextField fullWidth label="Ship To Contact Number" name="shipToContactNumber" value={formData.shipToContactNumber} onChange={handleInputChange} />
          </Grid>
        </Grid>

           <Divider style={{ margin: '20px 0' }} />

        {/* Country Selection */}
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Country</Typography>
        <RadioGroup row name="country" value={formData.country} onChange={handleCountryChange}>
          <FormControlLabel value="international" control={<Radio />} label="International" />
          <FormControlLabel value="india" control={<Radio />} label="India" />
        </RadioGroup>

        {/* Items Section */}
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Items</Typography>
        <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#941B0C' }}>
              <TableRow>
                <TableCell style={{ color: '#fff' }}>Qty</TableCell>
                <TableCell style={{ color: '#fff' }}>Item</TableCell>
                <TableCell style={{ color: '#fff' }}>Description</TableCell>
                <TableCell style={{ color: '#fff' }}>Unit Price</TableCell>
                <TableCell style={{ color: '#fff' }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      name="qty"
                      value={item.qty}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="item"
                      value={item.item}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="description"
                      value={item.description}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="unitPrice"
                      value={item.unitPrice}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="total"
                      value={item.total}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" onClick={addItem} style={{ marginBottom: '20px' }}>Add Item</Button>

        <Divider style={{ margin: '20px 0' }} />


        {/* Total Section */}
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={4}>
            <Typography variant="body1">Subtotal: ${formData.subtotal.toFixed(2)}</Typography>
           
        <Grid container spacing={2}> <Grid item xs={6}> <TextField fullWidth label="Sale Tax Percentage" name="saleTaxPercentage" value={formData.saleTaxPercentage} onChange={handleInputChange} onBlur={calculateTotal} /> </Grid> <Grid item xs={6}> <TextField fullWidth label="VAT/GST/Sales tax" name="salesTax" value={formData.salesTax} onChange={handleInputChange} onBlur={calculateTotal} /> </Grid> </Grid>
        
            <TextField
              fullWidth
              label="Shipping Charge"
              name="shippingCharge"
              value={formData.shippingCharge}
              onChange={handleInputChange}
              onBlur={calculateTotal}
            />
            <Typography variant="h6" style={{ marginTop: '10px' }}>
              Grand Total: ${formData.grandTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

        {/* Signature Section */}
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Signatures</Typography>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Vendor Signature"
              name="vendorSignature"
              value={formData.vendorSignature}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Date"
              type="date"
              name="vendorSignatureDate"
              value={formData.vendorSignatureDate}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Customer Signature"
              name="customerSignature"
              value={formData.customerSignature}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Date"
             type="date"
              name="customerSignatureDate"
              value={formData.customerSignatureDate}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
           {/* Terms and Conditions */}
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Terms and Conditions</Typography>
        <TextareaAutosize
          style={{ width: '100%', height: '40px' }}
          label="Terms and Conditions"
          name="terms"
          value={formData.terms}
          onChange={handleInputChange}
          multiline
          rows={4}
        />
      
      </div>
    </div>
</Box>
</Box>
  );
};

export default PurchaseOrder;
