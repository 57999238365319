import React, { useState } from 'react';
import {  AppBar, Toolbar, Box , Grid, TextField, Button, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import axiosInstance from '../common/axios';


    
    
    

const Report = () => {
    
        
      const navigate = useNavigate(); 
      const { user } = useAuth();
        const { email, name, userId, roles, userTypes} =  user || {};
    
    
  const [reportType, setReportType] = useState('quote');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [reportData, setReportData] = useState([]);
  
  // Columns for different report types
  const columns = {
    quote: [
      { field: 'quoteUniqueId', headerName: 'Quote ID', width: 130 },
      { field: 'createdDateTime', headerName: 'Created Date', width: 180 },
      { field: 'updatedDateTime', headerName: 'Updated Date', width: 180 },
      { field: 'message', headerName: 'Quote Message', width: 250 },
      { field: 'status', headerName: 'Status', width: 130 },
    ],
    invoice: [
      { field: 'id', headerName: 'Invoice ID', width: 130 },
      { field: 'invoiceNumber', headerName: 'Invoice Unique ID', width: 180 },
      { field: 'createdDate', headerName: 'Created Date', width: 180 },
      { field: 'date', headerName: 'Invoice Date', width: 180 },
      { field: 'exporter', headerName: 'Vendor', width: 130 },
      { field: 'consignee', headerName: 'Supplier', width: 130 },
      { field: 'invoiceStatus', headerName: 'Status', width: 130 },
    ],
    purchaseOrder: [
      { field: 'orderUniqueId', headerName: 'Order ID', width: 130 },
      { field: 'poUniqueId', headerName: 'PO Unique ID', width: 180 },
      { field: 'createdDate', headerName: 'Created Date', width: 180 },
      { field: 'updatedDate', headerName: 'Updated Date', width: 180 },
      { field: 'vendorContactName', headerName: 'Vendor', width: 130 },
      { field: 'shiptoContactName', headerName: 'Supplier', width: 130 },
      { field: 'poStatus', headerName: 'Status', width: 130 },
    ],
          order: [
      { field: 'orderId', headerName: 'PO ID', width: 130 },
      { field: 'quoteUniqueId', headerName: 'Quote Unique ID', width: 180 },
      { field: 'createdDate', headerName: 'Created Date', width: 180 },
      { field: 'updatedDate', headerName: 'Updated Date', width: 180 },
      { field: 'name', headerName: 'Vendor', width: 130 },
      { field: 'orderStatus', headerName: 'Status', width: 130 },
    ],
  };

  const handleGenerateReport = async () => {
    try {
        
       if(reportType === 'purchaseOrder'){
           const fromDateObj = new Date(fromDate);
const toDateObj = new Date(toDate);
           
            const response = await axiosInstance.get(`/api/purchase-orders/report`, { params: { 
    fromDate: fromDateObj.toISOString(), 
    toDate: toDateObj.toISOString() 
  } });
      setReportData(response.data);
       }else if(reportType === 'quote') {
           
             const fromDateObj = new Date(fromDate);
const toDateObj = new Date(toDate);
           
            const response = await axiosInstance.get(`/api/quote-request/report`, { params: { 
    fromDate: fromDateObj.toISOString(), 
    toDate: toDateObj.toISOString() 
  } });
      setReportData(response.data);
       }else if(reportType === 'invoice') {
           
             const fromDateObj = new Date(fromDate);
const toDateObj = new Date(toDate);
           
            const response = await axiosInstance.get(`/api/invoices/report`, { params: { 
    fromDate: fromDateObj.toISOString(), 
    toDate: toDateObj.toISOString() 
  } });
      setReportData(response.data);
       }else if(reportType === 'order') {
           
             const fromDateObj = new Date(fromDate);
const toDateObj = new Date(toDate);
           
            const response = await axiosInstance.get(`/api/orders/report`, { params: { 
    fromDate: fromDateObj.toISOString(), 
    toDate: toDateObj.toISOString() 
  } });
      setReportData(response.data);
       } else {
       const response = await axios.get(`/api/${reportType}`, { params: { fromDate, toDate } });
      setReportData(response.data);
       } 
        

    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    doc.text(`${reportType.charAt(0).toUpperCase() + reportType.slice(1)} Report`, 10, 10);
    autoTable(doc, {
      head: [columns[reportType].map((col) => col.headerName)],
      body: reportData.map((row) => columns[reportType].map((col) => row[col.field])),
    });
    doc.save(`${reportType}_report.pdf`);
  };

  const downloadAsExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${reportType}Report`);
    XLSX.writeFile(workbook, `${reportType}_report.xlsx`);
  };


  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <div>
      <Typography variant="h5" gutterBottom>
        Generate Report
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="From Date"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">Report Type</Typography>
          <RadioGroup
            row
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <FormControlLabel value="quote" control={<Radio />} label="Quote" />
            <FormControlLabel value="invoice" control={<Radio />} label="Invoice" />
            <FormControlLabel value="purchaseOrder" control={<Radio />} label="Purchase Order" />
                 <FormControlLabel value="order" control={<Radio />} label="Order" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleGenerateReport}>
            Generate
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            rows={reportData}
            columns={columns[reportType]}
            pageSize={5}
            getRowId={(row) => {
              switch (reportType) {
                case 'quote': return row.quoteUniqueId;
                case 'invoice': return row.id;
                case 'purchaseOrder': return row.poUniqueId;
                case 'order': return row.orderId;
                default: return row.id;
              }
            }}
            autoHeight
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" color="secondary" onClick={downloadAsPDF} style={{ marginRight: '10px' }}>
            Download as PDF
          </Button>
          <Button variant="outlined" color="secondary" onClick={downloadAsExcel}>
            Download as Excel
          </Button>
        </Grid>
      </Grid>
    </div>
</Box>
</Box>
  );
};

export default Report;
