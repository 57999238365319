import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
    


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);

    try {
      // Send the email to your backend API to check if the user exists
      const response = await axiosInstance.get(`/api/users/check?email=${email}`);

        const user = response.data;
      // If the email exists, navigate to the "New Password" page
      if (response.data.userId) {
          
        navigate(`/reset-password`, { state: { user } }); 
      } else {
        setErrorMessage('Email ID does not exist.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking the email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>

        {/* Error Message Display */}
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {errorMessage}
          </Alert>
        )}

        {/* Email Input */}
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? 'Checking...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
