import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {  AppBar, Toolbar, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, OutlinedInput, Grid, ListItemText, Container, TableCell, TableBody, TableRow , TableHead, Table, Paper } from '@mui/material';
import { useNavigate  } from 'react-router-dom';  // To get user email from login
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
//import logo from "images/cosmicwebwork_trim.png";

//import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-ppdf/renderer';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import axiosInstance from '../common/axios';



    

const styles = StyleSheet.create({ 
  page: { padding: 30 },
  section: { marginBottom: 10 },
  header: { fontSize: 20, marginBottom: 10 },
  text: { fontSize: 12 },
  table: {
    display: 'table', width: 'auto', borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,
  },
  tableRow: { flexDirection: 'row' },
  tableCol: { width: '25%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
  tableCell: { margin: 5, fontSize: 10 },
      tablePageCol: { width: '50%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    
      tableSubRow: { flexDirection: 'row' , borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    
});

const InvoicePDF = ({ data }) => ( 

  
  <Document>
    <Page size="A4" style={styles.page}>

      <View style={styles.section}>
        <Text style={styles.header}> COMMERCIAL INVOICE</Text>
      </View>
      <View style={styles.table}> 
      <View style={styles.tableRow}>
      <View style={styles.tablePageCol}>
    
       <View style={styles.section}>
        <Text style={styles.text}>Exporter: {data.exporter.name}</Text>
        <Text style={styles.text}>Address: {data.exporter.address}</Text>
        <Text style={styles.text}>Phone: {data.exporter.phone}</Text>
        <Text style={styles.text}>Contact: {data.exporter.contact}</Text>
          </View>
      </View>
      <View style={styles.tablePageCol}>
          <View style={styles.tableSubRow}>
        <Text style={styles.text}>Invoice Number: {data.invoiceNumber}</Text>
       </View>
      <View style={styles.tableSubRow}>
        <Text style={styles.text}>Date: {data.date}</Text>
          </View>
      <View style={styles.tableSubRow}>
        <Text style={styles.text}>Bill of Lading Number: {data.billOfLadingNumber}</Text>
          </View>
    
        <Text style={styles.text}>Other References: {data.otherReferences}</Text>
      
          </View>
 
    </View>
    

      <View style={styles.tableRow}>
      <View style={styles.tablePageCol}>
    
      <View style={styles.section}>
        <Text style={styles.text}>Consignee: {data.consignee.name}</Text>
        <Text style={styles.text}>Address: {data.consignee.address}</Text>
        <Text style={styles.text}>Phone: {data.consignee.phone}</Text>
        <Text style={styles.text}>Contact: {data.consignee.contact}</Text>
      </View>
      </View>
       <View style={styles.tablePageCol}>
    
      <View style={styles.section}>
        <Text style={styles.text}>if not Cosignee, Buyer: {data.buyer.name}</Text>
        <Text style={styles.text}>Address: {data.buyer.address}</Text>
        <Text style={styles.text}>Phone: {data.buyer.phone}</Text>
        <Text style={styles.text}>Contact: {data.buyer.contact}</Text>
      </View>
      </View>


      </View>
    
          <View style={styles.tableRow}>
      <View style={styles.tablePageCol}>
    
      <View style={styles.section}>
        <Text style={styles.text}>Vessel/Aircraft: {data.vessel}</Text>
        <Text style={styles.text}>Voyage No: {data.voyageNo}</Text>
        <Text style={styles.text}>Port of Loading: {data.portOfLoading}</Text>
        <Text style={styles.text}>Date of Departure: {data.dateOfDeparture}</Text> 
        <Text style={styles.text}>Port of Discharge: {data.portOfDischarge}</Text>
        <Text style={styles.text}>Final Destination: {data.finalDestination}</Text>
  
      </View>
      </View>

      <View style={styles.tablePageCol}>
          <View style={styles.section}>
        <Text style={styles.text}>Buyer Order No.: {data.buyerOrderNo}</Text>
        <Text style={styles.text}>Buyer Order Date: {data.buyerOrderDate}</Text>
        <Text style={styles.text}>Destination Through: {data.destinationThrough}</Text>
        <Text style={styles.text}>Dispatch Through: {data.dispatchedThrough}</Text>
        <Text style={styles.text}>Delivery NoteDate: {data.deliveryNoteDate}</Text>
   <Text style={styles.text}>Place of Receipt Of Shipment: {data.placeOfReceiptOfShipment}</Text>
      </View>
      </View>
      </View>

      <View style={styles.tableRow}>
    
         <View style={styles.tablePageCol}>
      <View style={styles.section}>
        <View style={styles.tableSubRow}>
        <Text style={styles.text}>Method of Dispatch: {data.methodOfDispatch}</Text>
       </View>
     <View style={styles.tableSubRow}>
        <Text style={styles.text}>Type of Shipment: {data.typeOfShipment}</Text>
    </View>
    <View style={styles.tableSubRow}>
        <Text style={styles.text}>Country of Origin: {data.countryOfOrigin}</Text>
     </View>
        <Text style={styles.text}>Country of Final Destination: {data.countryOfFinalDestination}</Text>

      </View>
    </View>
    
      <View style={styles.tablePageCol}>
          <View style={styles.section}>
        <View style={styles.tableSubRow}>
            <Text style={styles.text}>Terms of Delivery: {data.termsOfDelivery}</Text>
            <Text>{data.signatureName}</Text>
         </View>
          
            <Text style={styles.text}>Additional Information: {data.additonalinfo}</Text>
       
     </View>
    
     </View>
     </View>
    
    
      <View style={styles.section}>
        <Text style={styles.header}>Products</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Product Code</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Description</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>HS Code</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Quantity</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Unit Price</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Total</Text></View>
          </View>
          {data.products.map((product, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.code}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.description}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.hsCode}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.quantity}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.unitPrice}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{product.total}</Text></View>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.tableRow}>
          
      <View style={styles.tablePageCol}>
              <Text style={styles.text}>Amount in Words: {data.amountInWords}</Text>
       </View> 
        <View style={styles.tablePageCol}>

      <View style={styles.tableSubRow}>
          
        <Text style={styles.text}>Tax Percentage: {data.saleTaxPercentage}</Text>
       </View>

      <View style={styles.tableSubRow}>
        <Text style={styles.text}>Sales Taxes: {data.salesTax}</Text>
          </View>

      <View style={styles.tableSubRow}>
        <Text style={styles.text}>Gross Total: {data.grossTotal}</Text>
          </View>

      </View> 
   </View> 
 

      <View style={styles.tableRow}>
      <View style={styles.tablePageCol}>

     {/* Section 5: Bank Details */}
      <View style={styles.section}>
        <Text style={styles.text}>Bank Details:</Text>
        <Text style={styles.text}>{data.bankName}</Text>
        <Text>Account Number: {data.accountNumber}</Text>
        <Text style={styles.text}>SWIFT Code: {data.swiftCode}</Text>
      </View>
  </View>

  <View style={styles.tablePageCol}>
      <View style={styles.section}>
        <Text style={styles.text}>Terms/Method of Payment: {data.paymentTerms}</Text>
        <Text style={styles.text}>Marine Cover Policy No: {data.marineCoverPolicyNo}</Text>
        <Text style={styles.text}>Letter of Credit No: {data.letterOfCreditNo}</Text>
      </View>
  </View> 
  </View>

      <View style={styles.table}> 
      <View style={styles.tableRow}>
      <View style={styles.tablePageCol}>
          
            <View style={styles.section}>
        <Text style={styles.text}>Declaration:</Text>
        <Text style={styles.text}>We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct. </Text>
      </View>
 </View>

   <View style={styles.tablePageCol}>
      {/* Section 6: Signature */}
      <View style={styles.section}>

        <Text>{data.signatureName}</Text> 
  <Text style={styles.text}>Place  {data.signPlace}</Text>
 <Text style={styles.text}>Date  {data.signDate}</Text>
 <Text style={styles.text}> Name: {data.signName}</Text>
        <Text style={styles.text}>Authorized Signature:</Text>
      </View>
 </View>

      </View>
      </View>

</View>
<View style={styles.text}> Computer Generator Invoice</View>
    </Page>
  </Document>
);


const InvoiceForm = () => {
    
        const navigate = useNavigate(); 
    const { user } = useAuth();
    const { email, name, roles, userTypes} =  user || {};
    
    
    
  const [formData, setFormData] = useState({
    exporter: { name: '', address: '', phone: '', contact: '' },
    consignee: { name: '', address: '', phone: '', contact: '' },
      buyer: { name: '', address: '', phone: '', contact: '' },
    invoiceNumber: '',
    date: '',
    billOfLadingNumber: '',
    methodOfDispatch: '',
    typeOfShipment: '',
    countryOfOrigin: '',
    countryOfFinalDestination: '',
    vessel: '',
    voyageNo: '',
    portOfLoading: '',
    dateOfDeparture: '',
    portOfDischarge: '',
    finalDestination: '',
    paymentTerms: '',
    marineCoverPolicyNo: '',
    letterOfCreditNo: '',
    products: [{ code: '', description: '', hsCode: '', quantity: '', unitPrice: '', total: '' }],
    saleTaxPercentage: '',
      salesTax: '',
      grossTotal: '',
      amountInWords: '',
    otherReferences: '',
    destinationThrough: '',
    dispatchedThrough: '',
    deliveryNoteDate: '',
      placeOfReceiptOfShipment: '',
      termsOfDelivery: '',
      buyerOrderNo: '',
      buyerOrderDate: '',
      additonalinfo: '',
      signPlace: '',
      signDate:'',
      signName: '',
      
  });
    
    
     const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
    

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, key] = name.split('.');
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: { ...prevFormData[section], [key]: value },
    }));
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...formData.products];
    newProducts[index][name] = value;
    setFormData({ ...formData, products: newProducts });
  };

  const addProduct = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: [...prevFormData.products, { code: '', description: '', hsCode: '', quantity: '', unitPrice: '', total: '' }],
    }));
  };
    


  const handleSubmit = (e) => {
    e.preventDefault();
      
        try {
      const response = axiosInstance.post('/api/invoices', formData);
      console.log('invoice data submitted successfully:', response.data);
        alert("invoice data submitted successfully");
      
         } catch (error) {
      console.error('Error submitting builder data:', error);
          alert("Error submitting builder data. Please contact adminstrator or try again later!!");
    }
    console.log(formData);
  };


    
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
             <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <h1>Invoice Form</h1>
       <form onSubmit={handleSubmit}>
        <div>
          <h2>Exporter</h2>
          <input type="text" name="exporter.name" placeholder="Name" value={formData.exporter.name} onChange={handleInputChange} />
          <input type="text" name="exporter.address" placeholder="Address" value={formData.exporter.address} onChange={handleInputChange} />
          <input type="text" name="exporter.phone" placeholder="Phone" value={formData.exporter.phone} onChange={handleInputChange} />
          <input type="text" name="exporter.contact" placeholder="Contact" value={formData.exporter.contact} onChange={handleInputChange} />
        </div>
        <div>
          <h2>Consignee</h2>
          <input type="text" name="consignee.name" placeholder="Name" value={formData.consignee.name} onChange={handleInputChange} />
          <input type="text" name="consignee.address" placeholder="Address" value={formData.consignee.address} onChange={handleInputChange} />
          <input type="text" name="consignee.phone" placeholder="Phone" value={formData.consignee.phone} onChange={handleInputChange} />
          <input type="text" name="consignee.contact" placeholder="Contact" value={formData.consignee.contact} onChange={handleInputChange} />
        </div>
      
              <div>
          <h2>Buyer</h2>
          <input type="text" name="buyer.name" placeholder="Name" value={formData.buyer.name} onChange={handleInputChange} />
          <input type="text" name="buyer.address" placeholder="Address" value={formData.buyer.address} onChange={handleInputChange} />
          <input type="text" name="buyer.phone" placeholder="Phone" value={formData.buyer.phone} onChange={handleInputChange} />
          <input type="text" name="buyer.contact" placeholder="Contact" value={formData.buyer.contact} onChange={handleInputChange} />
        </div>
      <div> 
        <label>Invoice Number:</label>
        <input type="text" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange} />
      </div>
      <div>
        <label>Date:</label>
        <input type="date" name="date" value={formData.date} onChange={handleChange} />
      </div>
      <div>
        <label>Bill of Lading Number:</label>
        <input type="text" name="billOfLadingNumber" value={formData.billOfLadingNumber} onChange={handleChange} />
      </div>
      <div>
        <label>Method of Dispatch:</label>
        <input type="text" name="methodOfDispatch" value={formData.methodOfDispatch} onChange={handleChange} />
      </div>
      <div>
        <label>Type of Shipment:</label>
        <input type="text" name="typeOfShipment" value={formData.typeOfShipment} onChange={handleChange} />
      </div>
      <div>
        <label>Country of Origin:</label>
        <input type="text" name="countryOfOrigin" value={formData.countryOfOrigin} onChange={handleChange} />
      </div>
      <div>
        <label>Country of Final Destination:</label>
        <input type="text" name="countryOfFinalDestination" value={formData.countryOfFinalDestination} onChange={handleChange} />
      </div>
      <div>
        <label>Vessel:</label>
        <input type="text" name="vessel" value={formData.vessel} onChange={handleChange} />
      </div>
      <div>
        <label>Voyage No:</label>
        <input type="text" name="voyageNo" value={formData.voyageNo} onChange={handleChange} />
      </div>
      <div>
        <label>Port of Loading:</label>
        <input type="text" name="portOfLoading" value={formData.portOfLoading} onChange={handleChange} />
      </div>
      <div>
        <label>Date of Departure:</label>
        <input type="date" name="dateOfDeparture" value={formData.dateOfDeparture} onChange={handleChange} />
      </div>
      <div>
        <label>Port of Discharge:</label>
        <input type="text" name="portOfDischarge" value={formData.portOfDischarge} onChange={handleChange} />
      </div>
      <div>
        <label>Final Destination:</label>
        <input type="text" name="finalDestination" value={formData.finalDestination} onChange={handleChange} />
      </div>
      <div>
        <label>Payment Terms:</label>
        <input type="text" name="paymentTerms" value={formData.paymentTerms} onChange={handleChange} />
      </div>
      <div>
        <label>Marine Cover Policy No:</label>
        <input type="text" name="marineCoverPolicyNo" value={formData.marineCoverPolicyNo} onChange={handleChange} />
      </div>
      <div>
        <label>Letter of Credit No:</label>
        <input type="text" name="letterOfCreditNo" value={formData.letterOfCreditNo} onChange={handleChange} />
      </div>
      
       <div>
        <label>Buyer Order No:</label>
        <input type="text" name="buyerOrderNo" value={formData.buyerOrderNo} onChange={handleChange} />
      </div>
      <div>
        <label> Sales Tax:</label>
        <input type="date" name="buyerOrderDate" value={formData.buyerOrderDate} onChange={handleChange} />
      </div>

      <div>
        <label> Place Of Receipt Of Shipment:</label>
        <input type="text" name="placeOfReceiptOfShipment" value={formData.placeOfReceiptOfShipment} onChange={handleChange} />
      </div>
      <div>
        <label> deliveryNoteDate:</label>
        <input type="date" name="deliveryNoteDate" value={formData.deliveryNoteDate} onChange={handleChange} />
      </div>
      
            <div>
        <label> Terms of Delivery:</label>
        <input type="text" name="termsOfDelivery" value={formData.termsOfDelivery} onChange={handleChange} />
      </div>

      <div>
        <label> Dispatched Through:</label>
        <input type="text" name="dispatchedThrough" value={formData.dispatchedThrough} onChange={handleChange} />
      </div>
 

      <div>
        <label>Destination Through:</label>
        <input type="text" name="destinationThrough" value={formData.destinationThrough} onChange={handleChange} />
      </div>

      <div>
        <label> other References:</label>
        <input type="text" name="otherReferences" value={formData.otherReferences} onChange={handleChange} />
      </div>
      
      
      <div>
        <h2>Products</h2>
        {formData.products.map((product, index) => (
          <div key={index}>
            <input type="text" name="code" placeholder="Code" value={product.code} onChange={(e) => handleProductChange(index, e)} />
            <input type="text" name="description" placeholder="Description" value={product.description} onChange={(e) => handleProductChange(index, e)} />
            <input type="text" name="hsCode" placeholder="HS Code" value={product.hsCode} onChange={(e) => handleProductChange(index, e)} />
            <input type="number" name="quantity" placeholder="Quantity" value={product.quantity} onChange={(e) => handleProductChange(index, e)} />
            <input type="number" name="unitPrice" placeholder="Unit Price" value={product.unitPrice} onChange={(e) => handleProductChange(index, e)} />
            <input type="number" name="total" placeholder="Total" value={product.total} onChange={(e) => handleProductChange(index, e)} />
          </div>
        ))}
        <button type="button" onClick={addProduct}>Add Product</button>
      </div>

   <div>
        <label>Sale TaxPercentage:</label>
        <input type="text" name="saleTaxPercentage" value={formData.saleTaxPercentage} onChange={handleChange} />
      </div>
      <div>
        <label> Sales Tax:</label>
        <input type="text" name="salesTax" value={formData.salesTax} onChange={handleChange} />
      </div>

      <div>
        <label> Shipping Charges:</label>
        <input type="text" name="shippingCharges" value={formData.shippingCharges} onChange={handleChange} />
      </div>
      <div>
        <label> GrossTotal:</label>
        <input type="text" name="grossTotal" value={formData.grossTotal} onChange={handleChange} />
      </div>

      <div>
        <label>Amount In Words:</label>
        <input type="text" name="amountInWords" value={formData.amountInWords} onChange={handleChange} />
      </div>

      <div>
        <label>Additonal info:</label>
        <input type="text" name="additonalinfo" value={formData.additonalinfo} onChange={handleChange} />
      </div>
      <div>
        <label> sign Place:</label>
        <input type="text" name="signPlace" value={formData.signPlace} onChange={handleChange} />
      </div>
      <div>
        <label>sign Date:</label>
        <input type="date" name="signDate" value={formData.signDate} onChange={handleChange} />
      </div>
      <div>
        <label> sign Name:</label>
        <input type="text" name="signName" value={formData.signName} onChange={handleChange} />
      </div>

      <button type="submit">Submit</button>

         <PDFDownloadLink document={<InvoicePDF data={formData} />} fileName="invoice.pdf">
          <Button variant="contained">Download Invoice PDF</Button>
        </PDFDownloadLink>

    </form>
</Box>
</Box>
</Box>
  );
};

export default InvoiceForm;




