import React, { useState, useRef } from "react";
import './GoodReceiptNote.css'; // CSS file for styling 
import { Button, TextField, Grid, AppBar, Toolbar, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const GoodsReceivedNote = () => {
    
         
      const navigate = useNavigate(); 
    
         const { user } = useAuth();
    
    const { email, name, roles, userTypes} =  user || {};
    
  const [formData, setFormData] = useState({
    grnNumber: "",
    date: "",
    deliveryNoteNumber: "",
    deliveryDate: "",
    carrierName: "",
    supplierName: "",
    supplierAddress: "",
    supplierContact: "",
    receivedCondition: "",
    comments: "",
    items: [
      { item: "", description: "", unit: "", qtyOrdered: "", qtyReceived: "", unitPrice: "", totalPrice: "" },
    ],
    totalItems: "",
    totalAmount: "",
    recievedByName: "",
    recievedDate: "",
    departmentName: "",
  });

     const grnRef = useRef(); // Reference to the GRN details section
    const [submitMessage, setSubmitMessage] = useState("");

  // Export to PDF
  const exportToPDF = async () => {
    const element = grnRef.current;

    // Generate PDF from the selected element
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Goods_Received_Note.pdf");
  };

  // Print the GRN
  const printGRN = () => {
    const printContent = grnRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload to reset the page
  };

    
    
  // Submit Form
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      // Replace with your backend endpoint
      const response = await axiosInstance.post("/api/goods-received-notes", formData);
      setSubmitMessage("Form submitted successfully!");
      console.log(response.data);
        setFormData({
    grnNumber: "",
    date: "",
    deliveryNoteNumber: "",
    deliveryDate: "",
    carrierName: "",
    supplierName: "",
    supplierAddress: "",
    supplierContact: "",
    receivedCondition: "",
    comments: "",
    items: [
      { item: "", description: "", unit: "", qtyOrdered: "", qtyReceived: "", unitPrice: "", totalPrice: "" },
    ],
    totalItems: "",
    totalAmount: "",
    recievedByName: "",
    recievedDate: "",
    departmentName: "",
  });
        
    } catch (error) {
      setSubmitMessage("Error submitting the form. Please try again.");
      console.error(error);
    }
  };
    
    
     const [poSearch, setPoSearch] = useState(""); // State for the PO search input
  const [poList, setPoList] = useState([]); // State for the PO list
  const [showPopup, setShowPopup] = useState(false); // State to toggle the PO popup
    
    
  const [orderSearch, setOrderSearch] = useState(""); // State for Order search input
  const [orderList, setOrderList] = useState([]); // State for Order list
  const [showOrderPopup, setShowOrderPopup] = useState(false); // State for Order popup

    
        
  const [grnSearch, setGrnSearch] = useState(""); // State for Order search input
  const [grnList, setGrnList] = useState([]); // State for Order list
  const [showGRNPopup, setShowGRNPopup] = useState(false); // State for Order popup


  // Fetch PO list based on the search
  const searchPO = async () => {
    // Replace this with your actual API endpoint
    const response =  await axiosInstance.get(`/api/purchase-orders/search?prefix=${poSearch}`);
   
    setPoList(response.data); // Assume the API returns a list of POs
    setShowPopup(true); // Show the popup with results
  };
    
     // Fetch Order list based on the search
  const searchOrder = async () => {
    const response = await fetch(`/api/orders?search=${orderSearch}`);
    const data = await response.json();
    setOrderList(data);
    setShowOrderPopup(true);
  };
    
    
    
  // Fetch PO list based on the search
  const searchGRN = async () => {
    // Replace this with your actual API endpoint
    const response =  await axiosInstance.get(`/api/goods-received-notes/search?prefix=${grnSearch}`);
   
    setGrnList(response.data); // Assume the API returns a list of POs
    setShowGRNPopup(true); // Show the popup with results
  };
    


  
  // Fetch PO details when a PO is selected
  const selectPO = async (poNumber) => {
    // Replace this with your actual API endpoint for fetching PO details
    const response = await axiosInstance.get(`/api/purchase-orders/${poNumber}`);
   const data = await response.data;
      console.log(data.vendor);
       setFormData({
    supplierName: data.vendor,
    supplierAddress: data.vendorAddress || '',
    supplierContact: data.vendorContactName || '',
     recievedByName: data.shipToContactName || '',      
                      items: data.items.map(item => ({
          qtyOrdered: item.qty || '',
          item: item.item || '',
          description: item.description || '',
          unitPrice: item.unitPrice || '',
          totalPrice: item.total || ''
        })) || [ { item: "", description: "", unit: "", qtyOrdered: "", qtyReceived: "", unitPrice: "", totalPrice: "" }],
           
    totalItems: data.items.length,
    totalAmount: data.grandTotal || 0,
       });


    setShowPopup(false); // Close the popup
  };
    

    
      // Fetch Order details when an Order is selected
  const selectOrder = async (orderNumber) => {
    const response = await fetch(`/api/orders/${orderNumber}`);
    const data = await response.json();

    setFormData({
      ...formData,
      grnNumber: data.grnNumber || "",
      date: data.date || "",
      deliveryNoteNumber: data.deliveryNoteNumber || "",
      deliveryDate: data.deliveryDate || "",
      carrierName: data.carrierName || "",
      supplierName: data.supplierName || "",
      supplierAddress: data.supplierAddress || "",
      supplierContact: data.supplierContact || "",
      items: data.items || [],
      totalItems: data.totalItems || "",
      totalAmount: data.totalAmount || "",
    });

    setShowOrderPopup(false);
  };
    
    
    
          // Fetch Order details when an Order is selected
  const selectGRN = async (grnNumber) => {
   const response = await axiosInstance.get(`/api/goods-received-notes/${grnNumber}`);
      const data = await response.data;

      console.log(data);
      
    setFormData({
      ...formData,
      grnNumber: data.grnNumber || "",
      date: data.date || "",
      deliveryNoteNumber: data.deliveryNoteNumber || "",
      deliveryDate: data.deliveryDate || "",
      carrierName: data.carrierName || "",
      supplierName: data.supplierName || "",
      supplierAddress: data.supplierAddress || "",
      supplierContact: data.supplierContact || "",
      items: data.items || [],
      totalItems: data.totalItems || "",
      totalAmount: data.totalAmount || "",
      receivedCondition: data.receivedCondition,
      comments: data.comments,
      recievedByName: data.recievedByName || "",
      recievedDate: data.recievedDate || "",
      departmentName: data.departmentName || "",
    });

    setShowGRNPopup(false);
  };
    
    
  // Handler for form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handler for table inputs
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.items];
    updatedItems[index][field] = value;
    setFormData({ ...formData, items: updatedItems });
  };

  // Add a new row to the items table
  const addItemRow = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { item: "", description: "", unit: "", qtyOrdered: "", qtyReceived: "", unitPrice: "", totalPrice: "" }],
    });
  };

   
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <div className="container">
      
      
        {/* PO Search Form */}
      <div className="section">
          <div className="form-group">
          <label>Search Order Unique Number:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={orderSearch}
              onChange={(e) => setOrderSearch(e.target.value)}
              placeholder="Enter Order Unique Number"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchOrder}>Search</button>
          </div>
        </div>
      
    
      
         {/* Order Popup Modal */}
      {showOrderPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select Order Unique Number</h3>
            <ul>
              {orderList.map((order) => (
                <li key={order.orderNumber} onClick={() => selectOrder(order.orderNumber)}>
                  {order.orderNumber} - {order.supplierName}
                </li>
              ))}
            </ul>
            <button onClick={() => setShowOrderPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
      
      
        <div className="form-group">
          <label>Search PO Number:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={poSearch}
              onChange={(e) => setPoSearch(e.target.value)}
              placeholder="Enter PO Number"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchPO}>Search</button>
          </div>
        </div>
     </div>
  



      {/* Popup Modal for PO Selection */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select PO Number</h3>
            <ul>
              {poList.map((po) => (
                <li key={po.poUniqueId} onClick={() => selectPO(po.id)}>
                  {po.poUniqueId} - {po.poStatus}
                </li>
              ))}
            </ul>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
      
      
        <div className="form-group">
          <label>Search GRN Number:</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={grnSearch}
              onChange={(e) => setGrnSearch(e.target.value)}
              placeholder="Enter GRN Number"
              style={{ marginRight: "10px" }}
            />
            <button onClick={searchGRN}>Search</button>
          </div>
        </div>


      {/* Popup Modal for PO Selection */}
      {showGRNPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Select GRN Number</h3>
            <ul>
              {grnList.map((grn) => (
                <li key={grn.id} onClick={() => selectGRN(grn.id)}>
                  {grn.grnNumber} - {grn.id}
                </li>
              ))}
            </ul>
            <button onClick={() => setShowGRNPopup(false)}>Close</button>
          </div>
        </div>
      )}
      
      
         {/* Buttons for Export to PDF and Print */}
      <div className="action-buttons" style={{ marginBottom: "20px" }}>
        <button onClick={exportToPDF}>Export to PDF</button>
        <button onClick={printGRN}>Print</button>
             <button onClick={submitForm}>Submit</button>
      </div>

      {/* Submission Message */}
      {submitMessage && <p style={{ color: submitMessage.includes("Error") ? "red" : "green" }}>{submitMessage}</p>}

      {/* GRN Details Section */}
  <div ref={grnRef}>
      <div className="header">GOODS RECEIVED NOTE</div>

      <div className="section">
        <div className="form-group">
          <label>GRN Number:</label>
          <input type="text" name="grnNumber" value={formData.grnNumber} onChange={handleInputChange} placeholder="Enter GRN Number" />
        </div>
        <div className="form-group">
          <label>Date:</label>
          <input type="date" name="date" value={formData.date} onChange={handleInputChange} />
        </div>
      </div>

      <div className="section">
        <h3>Delivery Information</h3>
        <div className="form-group">
          <label>Delivery Note Number:</label>
          <input type="text" name="deliveryNoteNumber" value={formData.deliveryNoteNumber} onChange={handleInputChange} placeholder="Enter Delivery Note Number" />
        </div>
        <div className="form-group">
          <label>Delivery Date:</label>
          <input type="date" name="deliveryDate" value={formData.deliveryDate} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label>Carrier/Driver Name:</label>
          <input type="text" name="carrierName" value={formData.carrierName} onChange={handleInputChange} placeholder="Enter Carrier/Driver Name" />
        </div>
      </div>

      <div className="section">
        <h3>Supplier Information</h3>
        <div className="form-group">
          <label>Supplier Name:</label>
          <input type="text" name="supplierName" value={formData.supplierName} onChange={handleInputChange} placeholder="Enter Supplier Name" />
        </div>
        <div className="form-group">
          <label>Supplier Address:</label>
          <input type="text" name="supplierAddress" value={formData.supplierAddress} onChange={handleInputChange} placeholder="Enter Supplier Address" />
        </div>
        <div className="form-group">
          <label>Supplier Contact Information:</label>
          <input type="text" name="supplierContact" value={formData.supplierContact} onChange={handleInputChange} placeholder="Enter Supplier Contact" />
        </div>
      </div>
      
            <div className="section">
        <h3>Recieved By</h3>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="recievedByName" value={formData.recievedByName} onChange={handleInputChange} placeholder="Enter Delivery Note Number" />
        </div>
        <div className="form-group">
          <label>Recieved Date:</label>
          <input type="date" name="recievedDate" value={formData.recievedDate} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label>Department :</label>
          <input type="text" name="departmentName" value={formData.departmentName} onChange={handleInputChange} placeholder="Enter Carrier/Driver Name" />
        </div>
      </div>


      <div className="section">
        <h3>Received Items</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Description</th>
              <th>Unit of Measure</th>
              <th>Quantity Ordered</th>
              <th>Quantity Received</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {formData.items.map((item, index) => (
              <tr key={index}>
                {["item", "description", "unit", "qtyOrdered", "qtyReceived", "unitPrice", "totalPrice"].map((field) => (
                  <td key={field}>
                    <input
                      type="text"
                      value={item[field]}
                      onChange={(e) => handleItemChange(index, field, e.target.value)}
                      placeholder={`Enter ${field}`}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={addItemRow}>Add Row</button>
      </div>

      <div className="section">
        <h3>Totals</h3>
        <div className="totals">
          <div>
            Total Items:{" "}
            <input type="number" name="totalItems" value={formData.totalItems} onChange={handleInputChange} />
          </div>
          <div>
            Total Amount:{" "}
            <input type="number" name="totalAmount" value={formData.totalAmount} onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="section">
        <h3>Received Condition</h3>
        <textarea
          name="receivedCondition"
          value={formData.receivedCondition}
          onChange={handleInputChange}
          rows="3"
          placeholder="Enter Received Condition"
        ></textarea>
      </div>

      <div className="section">
        <h3>Comments</h3>
        <textarea
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
          rows="4"
          placeholder="Enter Comments"
        ></textarea>
</div>
</div>
  </div>

</Box>
</Box>
  );
};

export default GoodsReceivedNote;
