import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Typography, AppBar,Toolbar } from '@mui/material';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import {countryCodes} from '../common/countries';
import { useNavigate  } from 'react-router-dom';  
import axiosInstance from '../common/axios';

const Traders = () => {
  const [traderData, setTraderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

                 const navigate = useNavigate(); 
         const { user } = useAuth();
    const { email, name, roles, userTypes} =  user || {};
    
  useEffect(() => {
    // Fetch data from the API
    const fetchTraderData = async () => {
      try {
        const response = await axiosInstance.get('/api/traders'); // Replace with your actual API endpoint
        setTraderData(response.data);
      } catch (error) {
        console.error('Error fetching trader data:', error);
      }
    };

    fetchTraderData();
  }, []);

  // Define the columns for the DataGrid
  const columns = [
    { field: 'traderId', headerName: 'Trader ID', width: 120, sortable: true },
    { field: 'traderName', headerName: 'Trader Name', width: 200, sortable: true },
    { field: 'traderEmail', headerName: 'Trader Email', width: 250, sortable: true },
    { field: 'traderCountryCode', headerName: 'Country Code', width: 120, sortable: true },
    { field: 'traderPhone', headerName: 'Phone Number', width: 150, sortable: true },
    { field: 'address', headerName: 'Address', width: 300, sortable: true },
    { field: 'city', headerName: 'City', width: 150, sortable: true },
    { field: 'country', headerName: 'Country', width: 150, sortable: true },
  ];

  // Filter rows based on search query
  const filteredRows = traderData.filter((row) =>
    Object.values(row)
      .join(' ')
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <Box sx={{ height: 600, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Trader Data
      </Typography>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        getRowId={(row) => row.traderId} // Define the unique identifier for rows
        checkboxSelection
      />
    </Box>
 </Box>
 </Box>
  );
};

export default Traders;
