import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Typography,
    FormControlLabel,
    Grid,
    Checkbox,
    Box
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useDropzone } from 'react-dropzone';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const ConfirmationDialog = ({ open, handleClose, handleConfirm ,type }) => {
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [comment, setComment] = useState('');
  const [attachment, setAttachment] = useState('');
    
  const { user } = useAuth();
  const { email, name, userId, roles, userTypes} =  user || {};

  useEffect(() => {
    // Fetch statuses from the service
      let statusURL = "";
      if(type === 'order'){
        statusURL = `${backendUrl}/api/order-status`;
       }else if(type === 'quote'){
          
          statusURL = `${backendUrl}/api/quote-status`;
          
      }else{
          
          console.log("No status available");
      }
      
    axios.get(statusURL)
      .then(response => setStatuses(response.data))
      .catch(error => console.error('Error fetching statuses:', error));
      
  }, [type]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
      console.log("selected status "+event.target.value );
      if (event.target.value === 3001) {
          setAddressRequired(true);
      }else{
          setAddressRequired(false);
      }
      
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
     handleConfirm(selectedStatus, comment,attachment,formData)
  };

    
        // Handle file upload via button or dropzone
  const handleFileChange = (file) => {
    setAttachment(file);
  };

    
        
  const [formData, setFormData] = useState({
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
  });
    
    
      // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/msword': ['.doc', '.docx'],
      'application/octet-stream': ['.dwg', '.step', '.stl', '.iges'],
    },
    maxSize: 10 * 1024 * 1024, // 10 MB
  });
    
    
      const [sameAsBilling, setSameAsBilling] = useState(false);
  const [addressRequired, setAddressRequired] = useState(false);
    
  const handleAddressChange = (type, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], [field]: value },
    }));

   
    if (type === 'billingAddress' && sameAsBilling) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress, [field]: value },
      }));
    }
  };
    


  const handleSameAsBilling = (event) => {
    const isChecked = event.target.checked;
    setSameAsBilling(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress },
      }));
    }
  };

      const renderAddressFields = (label, type) => (
    <>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine1}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine1', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine2}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine2', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            fullWidth
            required
            value={formData[type].city}
            onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            fullWidth
            required
            value={formData[type].state}
            onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            fullWidth
            required
            value={formData[type].zipCode}
            onChange={(e) => handleAddressChange(type, 'zipCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            fullWidth
            required
            value={formData[type].country}
            onChange={(e) => handleAddressChange(type, 'country', e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );

    
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Status</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update this record?
        </DialogContentText>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {statuses.filter(status => (Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')) || status.statusName !== 'Supplier Responded')
            .map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.statusName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Comment"
          multiline
          rows={4}
          value={comment}
          onChange={handleCommentChange}
        />
          
         {addressRequired && (  
          <>
          {renderAddressFields("Billing Address", "billingAddress")}

      
      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsBilling}
            onChange={handleSameAsBilling}
            color="primary"
          />
        }
        label="Shipping address is the same as billing address"
      />

      {!sameAsBilling && renderAddressFields("Shipping Address", "shippingAddress")}
       </>       
)};
      
 {/* Attachment Field */}
      <Typography variant="body2" gutterBottom>
        Attachment: (Allowed types: .pdf, .dwg, .step, .stl, .iges, .doc, .docx, .png, .jpeg, .ppt, .pptx. Up to 10 MB)
      </Typography>

      {/* Dropzone Area */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2">Drop the files here ...</Typography>
        ) : (
          <Typography variant="body2">
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        )}
      </Box>

      {/* Upload Button */}
      <Button variant="outlined" component="label" sx={{ marginBottom: '20px' }}>
        Upload Attachment
        <input
          type="file"
          hidden
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
      </Button>

      {/* Show Uploaded File */}
      {attachment && (
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Uploaded file: {attachment.name}
        </Typography>
      )}          
              
              
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
