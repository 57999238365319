import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Button, Box } from "@mui/material";
import axiosInstance from '../common/axios';


import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const TermPdfViewer = ({ pdfId }) => {
  const [pdfUrl, setPdfUrl] = useState("");
    
    //test

    

  useEffect(() => {
    if (pdfId) {
      fetchPdf();
    }
  }, [pdfId]);

  const fetchPdf = async () => {
    try {
      // Fetch the PDF as a Blob
      const response = await axiosInstance.get(`/api/terms/view?filePath=${pdfId}`, {
        responseType: "blob",
      });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      setPdfUrl(blobUrl);
        
    } catch (error) {
      console.error("Error fetching PDF:", error);
      alert("Failed to fetch the PDF.");
    } 
  };
    

  return (
    <Box>
      {pdfUrl ? (
        <>
          <Typography variant="h6" gutterBottom>
            Term & Condition 
          </Typography>
          <iframe
            src={pdfUrl}
            width="100%"
            height="600px"
            title="Term PDF"
            style={{ border: "1px solid #ccc" }}
          ></iframe>
      
      
          <Button
            variant="contained"
            color="primary"
            href={pdfUrl}
            download={`Term-${pdfId}.pdf`}
            sx={{ mt: 2 }}
          >
            Download PDF
          </Button>
        </>
      ) : (
        <Typography variant="body1">Loading PDF...</Typography>
      )}

    </Box>
  );
};

export default TermPdfViewer;
