import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Box, Button, TextField,  Container,Typography, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate, Link  } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Login = () => {
    
    
    
        const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
      const navigate = useNavigate();

     const { login , logout } = useAuth();
    
    
    useEffect(() => {
         logout(); 
    // Call the onLogout function when the component loads
  //  onLogout();
  }, []);
    
    
    
const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage(''); 
       console.log('Email:', email );
               const newUser = {
              email: email,
              password: password
            };
      
     //  
  //  axios.post(`${backendUrl}/api/auth/login`, { email, password })
       axios.post(`${backendUrl}/api/users/login`, newUser)
        .then((response) => {
          const user = response.data;
             localStorage.setItem('token', user.token);
           console.log(user);
      if (user.active)  {
          // Redirect to dashboard if user exists
             console.log('Email:', response.data.userId);
            const userId = response.data.userId;
          if(user.authorized){
              login(user); 
           navigate('/dashboard');
             }else{
                 
                 if(user.provider){
                     console.log("you already registed using the SSO. Please login using SSO");
                     // Catch and handle errors
                     setErrorMessage('you already registed using the SSO. Please login using SSO.');
      
                 }else{
                     console.log("Please enter the correct password");
                     setErrorMessage('Please enter the correct password.');
                 }
             }
                
        } else {
            
            console.log(" u provider "+ user.provider );
               login(user); 
          // If user doesn't exist, navigate to user details and pass email & password
          navigate('/user-details');
        }
        
      
        }).catch(err => {
            console.error('Login failed', err);
           setErrorMessage('An error occurred during login. Please try again.');
        });
};
    
    
  
    
 

    
const handleGoogleLogin = (response) => {
    
 console.log("token:  " + response.credential); 
setErrorMessage(''); 
        
     const userObject = jwtDecode(response.credential); 
    
        const email = userObject.email;
    
        const name = userObject.name;
        const givenName = userObject.given_name;
       const  familyName = userObject.family_name;
      const emailVerified = userObject.email_verified;
        console.log('User Info:', userObject);
    
    
    
    axios.post(`${backendUrl}/api/auth/google`, {
        tokenId: response.credential,
    }).then((response) => {
        
            const user = response.data;
          localStorage.setItem('token', user.token);
        
        
          console.log("acti: "+ response.data.active);
        if (response.data.active) {
          // Redirect to dashboard if user exists
            const userId = response.data.userId;
            login(user); 
          navigate('/dashboard');
        } else {
               console.log('response.data.exists:', response.data.exists);
           const user = {
                  email:email,      // Replace with actual email
                  name:name,                  // Replace with actual name
                  givenName:givenName,                 // Replace with actual given name
                  familyName:familyName,
                  provider: 'GOOGLE',
                emailVerified:emailVerified
           };
      
               login(user); 
          // Redirect to UserDetails form if user doesn't exist
          navigate('/user-details');
        }
        
      
    }).catch(err => {
        console.error(err);
        // Catch and handle errors
      if (err.response && err.response.status === 401) {
        setErrorMessage('Please enter the correct password'); // Set error message
        console.log('Please enter the correct password');
      } else {
        setErrorMessage('An error occurred during login. Please try again.');
      }
    });
};

    

return (
    
    
          <div>
   <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'center',
        padding: 3,
      }}
    >
      {/* Google Login Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Login with Google
        </Typography>
    
     <GoogleOAuthProvider clientId={GoogleClientId}>
      <div className="App">
        <GoogleLogin onSuccess={handleGoogleLogin} onError={() => console.log('Error')} />
      </div>
    </GoogleOAuthProvider>

      </Box>
 {/* Error Message Display */}
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {errorMessage}
          </Alert>
        )}

      {/* Divider Text */}
      <Typography variant="h6" sx={{ my: 2 }}>
        Or Login with Email/Password
      </Typography>
  

      {/* Email/Password Login Form */}
      <form onSubmit={handleLogin} style={{ width: '100%', maxWidth: '400px' }}>
        {/* Email Input */}
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Password Input */}
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginBottom: 4 }}
        >
          Login with Email/Password
        </Button>
      </form>

 {/* Forgot Password Link */}
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <Link to="/forgot-password" style={{ textDecoration: 'none', color: '#3f51b5' }}>
            Forgot Password?
          </Link>
        </div>


</Container>
      </div>


);
};

export default Login;
