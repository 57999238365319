import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: backendUrl, // Your backend base URL
});

// Add a request interceptor to include the token in all requests
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Attach token to Authorization header
    }
    return config;
}, error => {
    // Handle request error
    return Promise.reject(error);
});

export default axiosInstance;
