import React, { useState,useEffect } from 'react';
import { AppBar, Toolbar,Box, TextField, Button,CircularProgress, Typography, Stepper, Step, StepLabel } from '@mui/material';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';


const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TrackerPage = () => {
     const { user } = useAuth();
    
    const { email, name, roles, userTypes,userId} =  user || {};
    
     const navigate = useNavigate(); 
  const [orderId, setOrderId] = useState('');
  const [workflow, setWorkflow] = useState([]);
  const [error, setError] = useState('');
      const [steps, setSteps] = useState([]); // Steps fetched from the service
  const [currentStep, setCurrentStep] = useState(0); // Active step in the workflow
  const [loading, setLoading] = useState(false); // Loading state for API calls



    
  // Fetch the steps when the component loads
  useEffect(() => {
    const fetchSteps = async () => {
      setLoading(true);
      try {
        // Fetching available steps from the service
        const response = await axios.get(`${backendUrl}/api/order-status`); // Adjust API endpoint as needed
        setSteps(response.data.map((step) => step.statusName)); // Assuming the service returns { id, stepName }
        setError('');
      } catch (error) {
        console.error('Error fetching steps:', error);
        setError('Failed to load steps. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSteps();
  }, []);


    // Handle order tracking by fetching the order status
  const handleTrackOrder = async () => {
    if (!orderId) {
      setError('Please enter an Order ID.');
      return;
    }

    setLoading(true);
    try {
      // Fetching order status by order ID
      const response = await axios.get(`${backendUrl}/api/orders/${orderId}/user/${userId}`); // Adjust API endpoint as needed
      const orderStatus = response.data.status.statusName; // Assuming status is a string matching a step name

      // Find the current step index and create the workflow array
      const stepIndex = steps.findIndex((step) => step.toLowerCase() === orderStatus.toLowerCase());
      if (stepIndex >= 0) {
        setWorkflow(steps.slice(0, stepIndex + 1)); // Set the workflow up to the current step
        setCurrentStep(stepIndex);
      } else {
        setWorkflow([]);
        setCurrentStep(0);
      }

      setError('');
    } catch (error) {
      console.error('Error fetching order status:', error);
      setError('Failed to track the order. Please check the Order ID and try again.');
    } finally {
      setLoading(false);
    }
  };
    
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
 <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Order Tracker
      </Typography>

      <TextField
        label="Enter Order ID"
        variant="outlined"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
        sx={{ marginBottom: 2 }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleTrackOrder}
        sx={{ marginBottom: 3 }}
        disabled={loading} // Disable button when loading
      >
        {loading ? 'Tracking...' : 'Track Order'}
      </Button>

      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}

      {/* Display the order workflow using a Stepper */}
      {workflow.length > 0 && (
        <Stepper activeStep={currentStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={workflow.includes(label)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {loading && <CircularProgress sx={{ marginTop: 2 }} />}
    </Box>
</Box>
</Box>
  );
};

export default TrackerPage;
