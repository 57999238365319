import React, { useState, useEffect } from 'react';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, TextField, Button, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Paper,   Select,
  MenuItem,
  FormControl,
  InputLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useLocation  } from 'react-router-dom'; 
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import { useDropzone } from 'react-dropzone';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const ReEstimation = () => {
 const location = useLocation();
     const navigate = useNavigate(); 
     const { user } = useAuth();
     const { email, name, roles, userId, userTypes} =  user || {};
     const {estimationId, estimationUniqueId }  = location.state;
     const [orders, setOrders] = useState([]);
     const [estimationStatus, setEstimationStatus] = useState([]);
     const [selectedStatus, setSelectedStatus] = useState('');
    
  const [formData, setFormData] = useState({
    name: name,
    reestimationDetails: '',
    attachment: null,
      statusId: selectedStatus,
  });

    
     const [companyTypes, setCompanyTypes] = useState([]);
    

      // Fetch company types and user types from the API when component mounts
  useEffect(() => {
          if (user) {
            setFormData({
                name,
                estimationUniqueId,
            });
        }
      
      getEstimationStatus();
      
      const fetchTypes = async () => {
      try {
        const companyResponse = await axiosInstance.get(`${backendUrl}/api/company-types`);
          console.log("companyResponse: "+ companyResponse );
          setCompanyTypes(companyResponse.data);
        
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
    fetchTypes();
      
       }, [user, estimationUniqueId,  name]);
    
    
    
      const getEstimationStatus = async () => {
    if (email) {
      // Fetch the quotes from API based on the user's email
        
    
        if(Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')){
            
            
           }
        
      axios.get(`${backendUrl}/api/estimation-status`)
        .then((response) => {
          setEstimationStatus(response.data);
        })
        .catch((error) => {
          console.error('Error fetching quotes:', error);
        });
    }
      }
    
    
      
 const handleStatusChange = (event) => {
     
      setFormData({ ...formData, statusId: event.target.value });
    setSelectedStatus(event.target.value);
  };
    
      
  const [errors, setErrors] = useState({
    nameError: '',
    reestimationDetailsError: '',
      estimationUniqueIdError: '',
    serverError: '',
  });

    
    
        // Handle file upload via button or dropzone
  const handleFileChange = (file) => {
    setFormData({ ...formData, attachment: file });
  };

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/msword': ['.doc', '.docx'],
      'application/octet-stream': ['.dwg', '.step', '.stl', '.iges'],
    },
    maxSize: 10 * 1024 * 1024, // 10 MB
  });
    
    
    
  // Handle form submission
    
    
  const submitForm = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(document.getElementById("requestReEstimationForm"));
    
            
  //    data.append("fileName", formData.attachment.name);
    
    //   data.append('attachment',formData.attachment, formData.attachment.name );
    
    // Validate form fields
    const name = document.getElementById("name").value;
    const reestimationDetails = document.getElementById("reestimationDetails").value;
    
    // Reset previous error messages
    const errorElements = document.getElementsByClassName('error');
    for (let i = 0; i < errorElements.length; i++) {
        errorElements[i].innerHTML = '';
    }

    if (!name) {
        document.getElementById('nameError').innerHTML = 'Name is required';
        return;
    }


    if (!estimationUniqueId) {
        document.getElementById('estimationUniqueIdError').innerHTML = 'Estimation ID is required';
        return;
    }
    
    if (!reestimationDetails) {
        document.getElementById('reestimationDetailsError').innerHTML = 'Estimation Details is required';
        return;
    }
        // Add the selected statusId manually
    formData.append("statusId", selectedStatus); 
    
    console.log(formData);
  axiosInstance.post(`${backendUrl}/api/reestimations/create`,formData)
    .then(response =>  {
       console.log(response);
      
        if (response.ok) {
            alert("Thank you forsubmitting the request.");
            document.getElementById("requestReEstimationForm").reset();
             setFormData({
                email,
                name
            });
            return;
        }
        return response.json();
        
    }).then(data => {
        if(data){
            if(typeof data.customMessage !== 'undefined'){
                document.getElementById('rserverError').innerHTML = data.customMessage;
            }
        }
    })
    .catch(error => {
        console.error('Error submitting the form:', error);
    });
  };




    // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
      
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '20px' }}>
      <Paper elevation={3} sx={{ padding: '30px', maxWidth: '600px', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Estimate 
        </Typography>
        <form id="requestReEstimationForm" onSubmit={submitForm} encType="multipart/form-data">
          {/* Server Error */}
          {errors.serverError && (
            <Typography variant="body2" color="error">
              {errors.serverError}
            </Typography>
          )}

<TextField
  label="Your Requestor"
  id="requestorId"
  name="requestorId"
  type="text"
  value={userId}
  sx={{ display: 'none' }}  // This hides the TextField
/>


<TextField
  label="Your Estimation"
  id="estimationId"
  type="hidden"
  value={estimationId}
  sx={{ display: 'none' }} 
/>


   {/* Name Field */}
          <TextField
            label="Estimation ID"
            id="estimationUniqueId"
            name="estimationUniqueId"
            fullWidth
            margin="normal"
            value={formData.estimationUniqueId}
            onChange={(e) => setFormData({ ...formData, estimationUniqueId: e.target.value })}
            required
            error={!!errors.estimationUniqueIdnameError}
            helperText={errors.estimationUniqueIdError}
    InputProps={{
        readOnly: true,
    }}
          />


          {/* Name Field */}
          <TextField
            label="Your Name"
            id="name"
            name="name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            error={!!errors.nameError}
            helperText={errors.nameError}
    InputProps={{
        readOnly: true,
    }}
          />

{(Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')) ? (
    <>
   <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
               id="statusId"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
          {estimationStatus.map((status) => (
    <MenuItem key={status.estimationStatusId} value={status.estimationStatusId}>
      {status.status}
    </MenuItem>
  ))}
          </Select>
        </FormControl>
        </>
              ) : (
<div></div>

)} 

     
 

             {/* Name Field */}
          <TextField
            label="Comments"
            id="comments"
            name="comments"
            fullWidth
            margin="normal"
            value={formData.comments}
            onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
            error={!!errors.commentsError}
            helperText={errors.commentsError}
 
          />

          {/* Message Field */}
          <TextField
            label="Estimation Details"
            id="reestimationDetails"
            name="reestimationDetails"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={formData.reestimationDetails}
            onChange={(e) => setFormData({ ...formData, reestimationDetails: e.target.value })}
            required
            error={!!errors.reestimationDetailsError}
            helperText={errors.reestimationDetailsError}
          />

   
 {/* Attachment Field */}
      <Typography variant="body2" gutterBottom>
        Attachment: (Allowed types: .pdf, .dwg, .step, .stl, .iges, .doc, .docx, .png, .jpeg, .ppt, .pptx. Up to 10 MB)
      </Typography>

      {/* Dropzone Area */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2">Drop the files here ...</Typography>
        ) : (
          <Typography variant="body2">
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        )}
      </Box>

      {/* Upload Button */}
      <Button variant="outlined" component="label" sx={{ marginBottom: '20px' }}>
        Upload Attachment
        <input
          type="file"
          hidden
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
      </Button>

      {/* Show Uploaded File */}
      {formData.attachment && (
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Uploaded file: {formData.attachment.name}
        </Typography>
      )}

          {/* Consent */}
          <Typography variant="body2" gutterBottom>
            we need your consent to communicate with you and to store and process your personal data. 
          </Typography>

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
</Box>
</Box>
  );
};

export default ReEstimation;
