import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../common/axios';

const ResetPassword = () => {
     const [verificationCode, setVerificationCode] = useState('');
     const [emailVerified, setEmailVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Access email from the state
  const user = location.state?.user;
  const userId = user.userId;
  
  
   const handleVerification = async () => {
    try {
      setErrorMessage('');
      // Make API call to verify the code
      const response = await axiosInstance.post(`/api/users/${userId}/validate/${verificationCode}`);

      if (response.data.emailVerified) {
        setEmailVerified(true);
      } else {
        setErrorMessage('Invalid verification code');
      }
    } catch (error) {
      setErrorMessage('Error verifying the code');
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
user.password = password;
    setLoading(true);

    try {
      // Send the new password to your backend API to update the user's password
      await axiosInstance.put(`/api/users/${userId}/reset-password` 
      , user );

      setSuccessMessage('Password successfully updated!');
      setTimeout(() => {
        navigate('/login'); // Navigate to login page after success
      }, 3000);
    } catch (error) {
      setErrorMessage('An error occurred while resetting the password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      
       <div style={{ width: '100%', maxWidth: '400px' }}>
      
      
        {!emailVerified && (
          <>
                    <Typography variant="h4" gutterBottom>
         Verify Your Email
        </Typography>
                  {/* Error Message Display */}
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {errorMessage}
          </Alert>
        )}

            <Typography variant="body2" gutterBottom>
              Please enter the verification code sent to your email.
            </Typography>
            <TextField
              label="Verification Code"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleVerification}>
              Verify Code
            </Button>
          </>
        )}

      
        {emailVerified && (
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>

        {/* Error Message Display */}
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {errorMessage}
          </Alert>
        )}

        {/* Success Message Display */}
        {successMessage && (
          <Alert severity="success" style={{ marginBottom: '20px' }}>
            {successMessage}
          </Alert>
        )}

        {/* New Password Input */}
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {/* Confirm Password Input */}
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? 'Resetting...' : 'Reset Password'}
        </Button>
      </form>
 )}
    </div>
 </div>
  );
};

export default ResetPassword;
