import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate  } from 'react-router-dom';  // To get user email from login
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { useAuth } from '../context/AuthContext';
import CommentPopup from '../components/CommentPopup';
import CustomerReviewPopup from '../components/CustomerReviewPopup';
import VerificationPopup from '../components/VerificationPopup';
import axiosInstance from '../common/axios';


import qs from 'qs'; 

const drawerWidth = 340;  // Width of the left-hand menu

 const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Dashboard = () => {
    
     const navigate = useNavigate(); 
    
      const { user } = useAuth();
    
  const { email, name, familyName, givenName, userId, roles , userTypes , partnerId, emailVerified} = user || {};  // Get the user's email passed from SSO login
    
  const [orders, setOrders] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [type, setType] = useState(null);
     const [loading, setLoading] = useState(false); 
    
    
    //review
    const [isReviewPopupOpen, setReviewPopupOpen] = useState(false);
  const openReviewPopup = () => {
      setReviewPopupOpen(true)
      ;
  }
  const closeReviewPopup = () => {
       
       setType(null);
      setReviewPopupOpen(false);
  }
    

  // Columns for Data Grid
  const orderColumns = [
    { field: 'orderId', headerName: 'Order ID', width: 150 },
    { field: 'name', headerName: 'Customer', width: 200 },
    { field: 'orderStatus', headerName: 'Status', width: 150 },
    { field: 'orderCreatedDate', headerName: 'Date', width: 150 },
        {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    renderCell: (params) => {
      if (Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')) {
     
        return (
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleApprove(params.row.orderId,'order')}
              style={{ marginRight: 8 }}
            >
              Update Order
            </Button>
       </>
      );
      }
      return null; // Return nothing if status is not "Provided"
    }
  },
     {
    field: 'view',
    headerName: 'View Comment',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleViewComments(params.row.orderId, 'order')}
      >
        View Details
      </Button>
    ),
  },
     {
    field: 'review',
    headerName: 'Review',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleReview(params.row.orderId, 'order')}
      >
       Review Order
      </Button>
    ),
  }
  ];

 const quoteColumns = [
  { field: 'quoteUniqueId', headerName: 'Quote ID', width: 200 },
  { field: 'name', headerName: 'Author', width: 150 },
      { field: 'message', headerName: 'Quote', width: 150 },
  { field: 'status', headerName: 'Status', width: 100 },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    renderCell: (params) => {
     
     if((Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN'))){
        return (
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleApprove(params.row.quoteUniqueId,'quote')}
              style={{ marginRight: 8 }}
            >
              Update Quote
            </Button>
       </>
      );
  } else if(params.row.status !== 'Approved'){
     
     return (
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleApprove(params.row.quoteUniqueId,'quote')}
              style={{ marginRight: 8 }}
            >
              Update Quote
            </Button>
       </>
      );
     } else {
     
      return null;
     }
     
     
     
     }
      
    },
     {
    field: 'view',
    headerName: 'View Comment',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleViewComments(params.row.quoteUniqueId,'quote')}
      >
        View Details
      </Button>
    ),
  }
];
    
      const [selectedId, setSelectedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

  const handleViewComments = (id, type) => {
    setSelectedId(id);
    setOpenPopup(true);
       setType(type);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
       setType(null);
  };

    
     const handleReview = (id, type) => {
         console.log(" review opn : ", isReviewPopupOpen)
    setSelectedId(id);
    setReviewPopupOpen(true);
       setType(type);
  };
  
   

  const handleApprove = (id, type) => {
    setSelectedId(id);
    setOpenDialog(true); // Open the confirmation dialog
      setType(type);
  };


    
  const handleConfirm = async (status, comment,attachment, address) => {
      
      let updatStatusURL = `${backendUrl}/api/quote-request/${selectedId}/update`
      let data = {
          userId:userId,
      statusId: status,
      comment: comment,
    };
      
      if(type==='order'){
          
          updatStatusURL = `${backendUrl}/api/orders/${selectedId}`
      data = {
          id: selectedId,
         comments: [
      { statusId: status, comment: comment,  userId:userId }
       ],
          billingAddress: address.billingAddress,
          shippingAddress: address.shippingAddress,
          
      }
          
          const formData = new FormData();
formData.append("order", new Blob([JSON.stringify(data)], { type: "application/json" }));
formData.append("attachment", attachment);
          
           await axiosInstance.put(updatStatusURL, formData)
      .then((response) => {
        console.log('Quote approved:', response.data);
        // Optionally refresh the grid or update the status locally
      })
      .catch((error) => {
        console.error('Error approving quote:', error);
      });
  }else{
      
      const formData = new FormData();
formData.append("commentDTO", new Blob([JSON.stringify(data)], { type: "application/json" }));
formData.append("attachment", attachment);
      
       await axios.post(updatStatusURL, formData)
      .then((response) => {
        console.log('Quote approved:', response.data);
        // Optionally refresh the grid or update the status locally
      })
      .catch((error) => {
        console.error('Error approving quote:', error);
      });
  }
       await fetchOrderQuote();
      
      console.log("update quote  " + userId + "status "+ status);
    setType(null);
    setOpenDialog(false); // Close the dialog after confirming
  };



const handleDecline = (id) => {
  console.log('Declined row with id:', id);
  // Add your logic to handle the decline action (e.g., update the status, make API calls, etc.)
};

    const fetchOrderQuote = async () => {
              console.log("emai: "+ email);
        console.log("name : "+ name);
       setLoading(true);
      const excludedQuoteStatuses = ['Approved', 'Declined'];  
      const excludedStatuses = ['Delivered'];  
      
    if (email) {
      // Fetch orders
        let orderURL = `${backendUrl}/api/users/${userId}/order/exclude-statuses`
        let quoteURL = `${backendUrl}/api/users/${userId}/quote/exclude-statuses`
        if(Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')){
             
            console.log("admin order url ")
           orderURL = `${backendUrl}/api/orders/exclude-statuses`;
           quoteURL = `${backendUrl}/api/quote-request/exclude-statuses`;
           }
        
        
      axiosInstance.get(orderURL , {
           params: {
    excludedStatuses: excludedStatuses
  },
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
      })
        .then(response => setOrders(response.data))
        .catch(error => console.error('Error fetching orders:', error));

axios.get(quoteURL, {
  params: {
    excludedStatuses: excludedQuoteStatuses
  },
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
})
  .then(response => setQuotes(response.data))
  .catch(error => console.error('Error fetching quotes:', error));
    }
        
        setLoading(false);
          
      }
    
    // Optional: Function to manually reload data (can be triggered by a button)
  const handleReload = () => {
    fetchOrderQuote(); // Fetch and update quotes
  };

  // Fetch pending/processing orders and quotes
  useEffect(() => {
        console.log("dashbord "+ emailVerified);
        console.log("dashbord "+ user.emailVerified);
   if(emailVerified){
       fetchOrderQuote();
   }else{
        setOpenVPopup(true);
   }
       
        
  }, [email]);  // Trigger when the email is available
    
      const [openVPopup, setOpenVPopup] = useState(false);
     const handleCloseVPopup = () => {
    setOpenVPopup(false);
  };

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
    const handleGRN = () => navigate('/grn');
    
    
    
  return (
      <div>
      {emailVerified ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
      handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
        />

        {/* Main Content Area */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* Main Content - Data Grids */}
          <Container sx={{ marginTop: '20px' }}>
            {/* Data Grid for Pending/Processing Orders */}
            <Typography variant="h6" gutterBottom>
              Pending/Processing Orders
            </Typography>
            <div style={{ height: 300, width: '100%', marginBottom: '40px' }}>
              <DataGrid rows={orders} columns={orderColumns} pageSize={5}  getRowId={(row) => row.orderId}  />
            </div>

            {/* Data Grid for Pending/Processing Quotes */}
            <Typography variant="h6" gutterBottom>
              Pending/Processing Quotes
            </Typography>
 
      
      <div style={{ height: 300, width: '100%' }}>
          <Button variant="contained" onClick={handleReload} sx={{ marginBottom: 2 }}>
        Reload Data
      </Button>
  <DataGrid 
    rows={quotes} 
    columns={quoteColumns} 
    pageSize={5} 
    getRowId={(row) => row.quoteUniqueId}
   rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-root': {
            overflowX: 'auto',
          },
        }}
  />
 {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
        type={type}
      />
            {openPopup && (
        <CommentPopup
          open={openPopup}
          handleClose={handleClosePopup}
          rowId={selectedId}
         type={type}
        />
      )}
  
</div>

          </Container>
        </Box>
      </Box>
    </Box>
) : (
    <div sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
       <VerificationPopup
        open={openVPopup}
        handleClose={handleCloseVPopup}
      />

    
    </div>

    
    )}

    <>
        
    <CustomerReviewPopup
        open={isReviewPopupOpen}
        onClose={closeReviewPopup}
        userId={userId} // Replace with dynamic customer ID as needed
        type={type}
        orderId={selectedId}
      />
    </>
</div>
  );
};

export default Dashboard;
