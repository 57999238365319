import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";

import LeftDrawer from '../components/LeftDrawer';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';

const TermsPopupDialog = ({ open, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    pdfId: "",
    description: "",
    typeOfTerm: "",
    expireDate: "",
  });
    
   const { user } = useAuth();
   const { email, name, roles, userTypes,userId} =  user || {};
   const navigate = useNavigate(); 

const [file, setFile] = useState(null);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSubmit = new FormData();

      // Append form data
    /*  for (const key in formData) {
        formDataToSubmit.append(key, formData[key]);
      } */
        
 formDataToSubmit.append('term', new Blob([JSON.stringify(formData)], { type: 'application/json' }));
        

      // Append file
      if (file) {
        formDataToSubmit.append("termFile", file);
      }

      // Axios POST request
      const response = await axiosInstance.post("/api/terms", formDataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Term submitted successfully:", response.data);
      alert("Term submitted successfully!");
    } catch (error) {
      console.error("Error submitting term:", error);
      alert("Failed to submit the term.");
    }
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Term</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Term Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="PDF ID"
            name="pdfId"
            value={formData.pdfId}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
          />
          <FormControl fullWidth>
            <InputLabel>Type of Term</InputLabel>
            <Select
              name="typeOfTerm"
              value={formData.typeOfTerm}
              onChange={handleChange}
              required
            >
              <MenuItem value="GENERAL">General</MenuItem>
              <MenuItem value="SPECIAL">Special</MenuItem>
              <MenuItem value="LIMITED">Limited</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Expire Date"
            name="expireDate"
            type="date"
            value={formData.expireDate}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
      
      <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
            >
              Upload Term File
              <input
                type="file"
                hidden
                accept=".pdf"
                onChange={handleFileChange}
              />
            </Button>
            {file && (
              <Typography variant="body2" color="textSecondary">
                Selected file: {file.name}
              </Typography>
            )}
          </Grid>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add Term
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsPopupDialog;
