import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  AppBar, Toolbar, Box, Button, MenuItem, FormControl, InputLabel, Select, TextField, Typography, OutlinedInput, Chip, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import LeftDrawer from '../components/LeftDrawer';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Profile = () => {

  const navigate = useNavigate();
    
  const { user , login } = useAuth();
    
const { email, name, givenName, familyName, userId, roles, provider,emailVerified, userTypes: userTypesFromUser } = user || {};

  const [formData, setFormData] = useState({
      emailId:email,
    name: '',
    givenName: '',
    familyName: '',
      provider:provider,
    company: '',
    companyType: '',
    userTypes: [], // Change to array to support multiple selections
          wouldBePartner: 0,
  });

  const [companyTypes, setCompanyTypes] = useState([]);
  const [userTypes, setUserTypes] = useState([]);

  // Fetch company types and user types from the API when component mounts
  useEffect(() => {
      
      console.log("Called User effect :  ");
      console.log("user" + user);
          if (user) {
            setFormData({
                email,
                name,
                givenName,
                familyName,
                provider,
    userTypes
            });
        }

    
    
    const fetchTypes = async () => {
      try {
        const companyResponse = await axiosInstance.get(`${backendUrl}/api/company-types`);
          console.log("companyResponse: "+ companyResponse );
          setCompanyTypes(companyResponse.data);
        const userResponse = await axiosInstance.get(`${backendUrl}/api/user-types`);
        setUserTypes(userResponse.data);
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
      

      
         const fetchUserData = async () => {
      try {
        const userResponse = await axiosInstance.get(`${backendUrl}/api/users/${userId}`);
          console.log("userResponse: "+ userResponse.data );
          const user = userResponse.data;
           console.log("user companyType : "+user.companyType);
         
            const storedUserTypeIds = user.userTypes.map(userType => userType.userTypeId);

          
            setFormData({
                email,
                name,
                givenName,
                familyName,
                provider,
                company:user.company,
   companyType: user.companyType,
    userTypes: storedUserTypeIds,
                    wouldBePartner: user.wouldBePartner,
            });
 
          
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
      
    fetchTypes();
      fetchUserData();
    }, [user, email, name, givenName, familyName]);
    
    
          
    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value, })); 
    };
      
    

  const handleSubmit = (e) => {
    e.preventDefault();
    const newUser = { ...formData, email };


    axiosInstance.post(`${backendUrl}/api/users`, newUser)
      .then((response) => {
         console.log("response data"+response);
           console.log("response data"+response.data);
        console.log("response data"+response.data.userId);
        
        const user = response.data;
        login(user);
        
        if (response.data.userId) {
       
       
        }
      })
      .catch((error) => {
        console.error('Error saving user:', error);
      });
  };


    
// Handler for User Type change (Multiple Select)
  const handleUserTypeChange = (event) => {
    const selectedIds = event.target.value; // Array of selected user type IDs
    setFormData((prevData) => ({
      ...prevData,
      userTypes: selectedIds, // Update only the userTypes field
    }));
  };
    
  /*  const handleUserTypeChange = (event) => {
  const selectedIds = event.target.value;
  setFormData({ ...formData, userTypes: selectedIds });
}; 
   */

    
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypesFromUser}
/>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 4,
        backgroundColor: '#f4f6f8'
      }}
    >
      <Typography variant="h4" gutterBottom>
        User Profile
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 600,
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3
        }}
      >
      
      <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={formData.emailId}
                    onChange={(e) => setFormData({ ...formData, emailId: e.target.value })}
                     required
   InputProps={{
        readOnly: !formData.emailId || formData.emailId.trim() === "",
    }}
                />
                        
        {/* Name Field */}
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          margin="normal"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />

        {/* Given Name Field */}
        <TextField
          fullWidth
          label="Given Name"
          variant="outlined"
          margin="normal"
          value={formData.givenName}
          onChange={(e) => setFormData({ ...formData, givenName: e.target.value })}
          required
        />

        {/* Family Name Field */}
        <TextField
          fullWidth
          label="Family Name"
          variant="outlined"
          margin="normal"
          value={formData.familyName}
          onChange={(e) => setFormData({ ...formData, familyName: e.target.value })}
          required
        />

        {/* Company Field */}
        <TextField
          fullWidth
          label="Company"
          variant="outlined"
          margin="normal"
          value={formData.company}
          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
          required
        />
              

              
              <FormControl fullWidth variant="outlined" margin="normal">
  <InputLabel id="companyType-label">Company Type</InputLabel>
  <Select
    labelId="companyType-label"
    id="companyType"
    value={formData.companyType?.typeName || ""}
    onChange={(e) => {
      const selectedType = companyTypes.find((type) => type.typeName === e.target.value);
      setFormData({ ...formData, companyType: selectedType });
    }}
    label="Company Type"
    required
  >
    <MenuItem value="">
      <em>Select Company Type</em>
    </MenuItem>
    {companyTypes.map((type) => (
      <MenuItem key={type.id} value={type.typeName}>
        {type.typeName}
      </MenuItem>
    ))}
  </Select>
</FormControl>



    {/* User Type Field (Multiple Select) */}
<FormControl fullWidth variant="outlined" margin="normal">
  <InputLabel id="userType-label">User Type</InputLabel>
  <Select
    labelId="userType-label"
    id="userType"
    multiple
    value={formData.userTypes}  // Array of selected type IDs
    onChange={handleUserTypeChange}  // Updated handler for storing IDs
    required
    input={<OutlinedInput id="select-multiple-chip" label="User Type" />}
    renderValue={(selected) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {/* Map over selected IDs and display their names */}
        {selected.map((userTypeId) => {
          const selectedType = userTypes.find((type) => type.userTypeId === userTypeId);
          return <Chip key={userTypeId} label={selectedType?.typeName} />;
        })}
      </Box>
    )}
  >
    {userTypes.map((type) => (
      <MenuItem key={type.userTypeId} value={type.userTypeId}>
        {type.typeName}
      </MenuItem>
    ))}
  </Select>
</FormControl>

  <FormControl component="fieldset">
    <FormLabel component="legend">Would you like to become partner with us? </FormLabel>
     <RadioGroup aria-label="wouldBePartner" name="wouldBePartner" value={formData.wouldBePartner} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>
    


        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
 </Box> </Box>
      </Box>
    </Box>
  );
};

export default Profile;
