import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, OutlinedInput, Checkbox, ListItemText,FormControlLabel, Grid, Radio, RadioGroup, FormLabel } from '@mui/material';
import { useNavigate  } from 'react-router-dom';  // To get user email from login
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import {countryCodes} from '../common/countries';
import axiosInstance from '../common/axios';


const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ManufacturingRegistration = () => { 
    
    
      const navigate = useNavigate(); 
    
         const { user } = useAuth();
    
    const { email, name, roles, userTypes} =  user || {};
    
  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    contactNumber: '',
    country: '',
    businessLicenseNumber: '',
    taxIdentificationNumber: '', 
    manufacturerCertifications: [],
    capabilities: [],
    employeeCount: '',
    annualTurnover: '',
    additionalInfo: '',
       officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    estYear: '',
	website: '',
	hasExportLicense: 0, 
  }); 



  const [error, setError] = useState('');
    const [capabilities, setCapabilities] = useState([]); // To store fetched capabilities
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  // Fetch capabilities from the backend service
  useEffect(() => {
    const fetchCapabilities = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/manufacturer-capabilities`); // Replace with your actual API endpoint
        const fetchedCapabilities = response.data.map((capability) => capability.capability);
        setCapabilities(fetchedCapabilities);
      } catch (error) {
        console.error('Error fetching capabilities:', error);
      }
    };
                const fetchCertifications = async () => {
      try {
        const certificationResponse = await axiosInstance.get(`/api/certifications`);
          console.log("certificationResponse: "+ certificationResponse );
          setCertifications(certificationResponse.data);
        
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
      
      fetchCertifications();

    fetchCapabilities();
  }, []);

  // Handle capability selection change
  const handleCapabilityChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      capabilities: typeof value === 'string' ? value.split(',') : value,
    });
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.companyName || !formData.email || !formData.contactNumber || !formData.country || !formData.capabilities.length) {
      setError('Please fill in all required fields');
      return;
    }
      
      
    try {
      const response = await axios.post(`${backendUrl}/api/manufacturing/register`, formData);
      if (response.data) {
        alert('Manufacturing company registered successfully!');
        setFormData({
          companyName: '',
          email: '',
          contactNumber: '',
          country: '',
             businessLicenseNumber: '',
    taxIdentificationNumber: '', 
    manufacturerCertifications: [],
          capabilities: [],
          employeeCount: '',
          annualTurnover: '',
          additionalInfo: '',
       officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
                estYear: '',
	website: '',
	hasExportLicense: '',
        });
        setError('');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Registration failed. Please try again.');
    }
  };
    
    
    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value, })); 
    };
    
    
     const [certifications, setCertifications] = useState([]);
        
  const handleCheckboxChange = (certification) => {
      console.log(" p cert "+ certification);
    const isSelected = formData.manufacturerCertifications.some(
      (cert) => cert.certification.id === certification
    );

    if (isSelected) {
      // Remove the certification if already selected
      setFormData((prev) => ({
        ...prev,
        manufacturerCertifications: prev.manufacturerCertifications.filter(
          (cert) => cert.certification.id !== certification
        ),
      }));
    } else {
      // Add the certification if not selected
      setFormData((prev) => ({
        ...prev,
        manufacturerCertifications: [
          ...prev.manufacturerCertifications,
          { certification: { id: certification }, issueDate: "", expiryDate: "" },
        ],
      }));
    }
  };

  // Handle issueDate and expirationDate changes
  const handleDateChange = (certificationId, field, value) => {
    setFormData((prev) => ({
      ...prev,
      manufacturerCertifications: prev.manufacturerCertifications.map((cert) =>
        cert.certification.id === certificationId
          ? { ...cert, [field]: value }
          : cert
      ),
    }));
  };
    
    
             const [sameAsBilling, setSameAsBilling] = useState(false);
      const [sameAsOffice, setSameAsOffice] = useState(false);
  const handleAddressChange = (type, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], [field]: value },
    }));

    if (type === 'officeAddress' && sameAsOffice) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress, [field]: value },
      }));
    }
    if (type === 'billingAddress' && sameAsBilling) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress, [field]: value },
      }));
    }
  };
    
  const handleSameAsOffice = (event) => {
    const isChecked = event.target.checked;
    setSameAsOffice(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress },
      }));
    }
  };

  const handleSameAsBilling = (event) => {
    const isChecked = event.target.checked;
    setSameAsBilling(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress },
      }));
    }
  };

    
  const renderAddressFields = (label, type) => (
    <>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine1}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine1', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine2}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine2', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            fullWidth
            required
            value={formData[type].city}
            onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            fullWidth
            required
            value={formData[type].state}
            onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            fullWidth
            required
            value={formData[type].zipCode}
            onChange={(e) => handleAddressChange(type, 'zipCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            fullWidth
            required
            value={formData[type].country}
            onChange={(e) => handleAddressChange(type, 'country', e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );


    
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>

    <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <h2>Manufacturing Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Company Name */}
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Email */}
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />


        {/* Country */}
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Country</InputLabel>
          <Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          >
            {countryCodes.map((country) => (
              <MenuItem key={country.code} value={country.country}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        {/* Contact Number */}
        <TextField
          fullWidth
          label="Contact Number"
          variant="outlined"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />
              
        {/* State */}
        <TextField
          fullWidth 
          label="Business License Number"
          variant="outlined"
          name="businessLicenseNumber"
          value={formData.businessLicenseNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/*  */}
        <TextField
          fullWidth
          label="Tax Identification Number"
          variant="outlined"
          name="taxIdentificationNumber"
          value={formData.taxIdentificationNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />


{/* Certifications Section */}
        <Typography variant="h6" gutterBottom>Select Certifications</Typography>

      <Grid item xs={12} sm={6}>
        {certifications.map((certification) => (
          <div key={certification.id}>
            <label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(certification.id)}
              />
              {certification.name}
            </label>
            {formData.manufacturerCertifications.some(
              (cert) => cert.certification.id === certification.id
            ) && (
              <div style={{ marginLeft: "20px" }}>
                <label>
                  Issue Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "issueDate",
                        e.target.value
                      )
                    }
                  />
                </label>
                <br />
                <label>
                  Expiration Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "expiryDate",
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
            )}
          </div>
        ))}
     </Grid>

        {/* State */}
        <TextField
          fullWidth 
          label="Established Year"
          variant="outlined"
          name="estYear"
          value={formData.estYear}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />


        {/* State */}
        <TextField
          fullWidth 
          label="Website"
          variant="outlined"
          name="website"
          value={formData.website}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />


   <FormControl component="fieldset">
    <FormLabel component="legend">Export License</FormLabel>
     <RadioGroup aria-label="hasExportLicense" name="hasExportLicense" value={formData.hasExportLicense} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>

       {/* Capabilities (Multiple Select) */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Capabilities</InputLabel>
        <Select
          name="capabilities"
          multiple
          value={formData.capabilities}
          onChange={handleCapabilityChange}
          input={<OutlinedInput label="Capabilities" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {capabilities.map((capability) => (
            <MenuItem key={capability} value={capability}>
              <Checkbox checked={formData.capabilities.indexOf(capability) > -1} />
              <ListItemText primary={capability} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>


        {/* Employee Count */}
        <TextField
          fullWidth
          label="Employee Count"
          variant="outlined"
          name="employeeCount"
          type="number"
          value={formData.employeeCount}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/* Annual Turnover */}
        <TextField
          fullWidth
          label="Annual Turnover (in USD)"
          variant="outlined"
          name="annualTurnover"
          type="number"
          value={formData.annualTurnover}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/* Additional Info */}
        <TextField
          fullWidth
          label="Additional Information"
          variant="outlined"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />


     {renderAddressFields("Office Address", "officeAddress")}

      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsOffice}
            onChange={handleSameAsOffice}
            color="primary"
          />
        }
        label="Billing address is the same as office address"
      />

      {!sameAsOffice && renderAddressFields("Billing Address", "billingAddress")}

      
      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsBilling}
            onChange={handleSameAsBilling}
            color="primary"
          />
        }
        label="Shipping address is the same as billing address"
      />

      {!sameAsBilling && renderAddressFields("Shipping Address", "shippingAddress")}
        <Button variant="contained" color="primary" type="submit">
          Register Manufacturing Company
        </Button>
      </form>
    </Box>
 </Box>
 </Box>
  );
};

export default ManufacturingRegistration;
