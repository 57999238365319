import React, { useState, useEffect } from 'react';
import {   AppBar, Toolbar, Box , Grid, Button, Menu, MenuItem, Typography, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import axiosInstance from '../common/axios';

const InvoiceView = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

     const [statusOptions, setStatusOptions] = useState([]);

          const navigate = useNavigate(); 
      const { user } = useAuth();
        const { email, name, userId, roles, userTypes} =  user || {};
    

  useEffect(() => {
    // Fetch invoices from the API
    const fetchInvoices = async () => {
      try {
        const response = await axiosInstance.get('/api/invoices');
        setInvoices(response.data);
        setFilteredInvoices(response.data); 
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };
      
       const fetchInvoiceStatus = async () => {
      try {
        const response = await axiosInstance.get('/api/invoices/status');
        setStatusOptions(response.data);
      } catch (error) {
        console.error("Error fetching purchase orders:", error);
      }
    };
      
    fetchInvoices();
       fetchInvoiceStatus();
  }, []);

  // Filter invoices based on status and search term
  useEffect(() => {
    const filtered = invoices.filter((invoice) =>
      (statusFilter ? invoice.status === statusFilter : true) &&
      (searchTerm ? invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) : true)
    );
    setFilteredInvoices(filtered);
  }, [statusFilter, searchTerm, invoices]);

  // Handle status update
  const handleStatusUpdate = async (invoiceId, statusId) => {
    try {
      await axiosInstance.put(`/api/invoices/${invoiceId}/status?statusId=${statusId}&userId=${userId}`);
     /* setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice.id === invoiceId ? { ...invoice, status: newStatus } : invoice
        )
      );*/
      setStatusAnchorEl(null);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Column definitions for DataGrid
  const columns = [
    { field: 'invoiceNumber', headerName: 'Invoice Number', width: 150 },
    { field: 'date', headerName: 'Invoice Date', width: 180 },
    { field: 'grossTotal', headerName: 'gross Total', width: 180 },
    { field: "exporter",
     headerName: 'Exporter Name', 
     width: 150,
       valueGetter: (params) => {
    return params?.name || 'N/A';
  },
    },
    { field: 'consignee', headerName: 'Consignee Name', width: 150,
           valueGetter: (params) => {
    return params?.name || 'N/A';
  },
 },
    { field: 'finalDestination', headerName: 'final Destination', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={(event) => {
            setStatusAnchorEl(event.currentTarget);
            setSelectedInvoiceId(params.row.id);
          }}
        >
          Update Status
        </Button>
      ),
    },
  ];

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <div>
      <Typography variant="h5" gutterBottom>
        Invoice List
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={6}>
          <TextField
            label="Search Invoice Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Filter by Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            fullWidth
            select
          >
            <MenuItem value="">All</MenuItem>
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <DataGrid
        rows={filteredInvoices}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.invoiceNumber}
        autoHeight
        sortingOrder={['asc', 'desc']}
        disableSelectionOnClick
      />

      {/* Status Menu */}
      <Menu
        anchorEl={statusAnchorEl}
        open={Boolean(statusAnchorEl)}
        onClose={() => setStatusAnchorEl(null)}
      >
        {statusOptions.map((status) => (
          <MenuItem
            key={status.id}
            onClick={() => handleStatusUpdate(selectedInvoiceId, status.id)}
          >
            {status.statusName}
          </MenuItem> 
        ))}
      </Menu>
    </div>
</Box>
</Box>
  );
};

export default InvoiceView;
