import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import fetchManufacturerData from '../services/fetchManufacturerData';
import { useAuth } from '../context/AuthContext'; // Assuming you have a context for Auth
import { AppBar, Toolbar, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import LeftDrawer from '../components/LeftDrawer';
import { useNavigate  } from 'react-router-dom'; 

const Manufacturers = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const navigate = useNavigate(); 
    
      const { user } = useAuth();
    
  const { email, name, familyName, givenName, userId, roles , userTypes } = user || {};  // Get the user's email passed from SSO login
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getManufacturerData = async () => {
      const manufacturerData = await fetchManufacturerData();
      setManufacturers(manufacturerData);
    };

    getManufacturerData();
  }, []);

 // Define columns matching the form fields
  const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'companyName', headerName: 'Company Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contactNumber', headerName: 'Contact Number', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'state', headerName: 'State', width: 150 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'postalCode', headerName: 'Postal Code', width: 100 },
    { field: 'addressLine1', headerName: 'Address Line 1', width: 200 },
    { field: 'addressLine2', headerName: 'Address Line 2', width: 200 },
{
  field: 'capabilities',
  headerName: 'Capabilities',
  width: 250,
  renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const capabilities = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.capability).join(', ') 
      : 'No capabilities'; // Fallback if no capabilities

    return <span>{capabilities}</span>; // Render the capabilities as a span
  },
},
    { field: 'certifications', headerName: 'Certifications', width: 200 },
    { field: 'employeeCount', headerName: 'Employee Count', width: 150 },
    { field: 'annualTurnover', headerName: 'Annual Turnover (USD)', width: 180 },
    { field: 'additionalInfo', headerName: 'Additional Info', width: 250 },
  ];

 if (Array.isArray(user?.userRoles) && !user?.userRoles.some(userRole => userRole.role === 'ROLE_ADMIN'))  {
    return <p>Access Denied. You must be an admin to view this content.</p>;
  }

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <Box sx={{ width: '100%', height: 400 }}>
      <DataGrid
        rows={manufacturers}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={isMobile ? 5 : 10}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-root': {
            overflowX: 'auto',
          },
          '@media (max-width: 600px)': {
            '& .MuiDataGrid-columnHeaders': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '12px',
            },
          },
        }}
      />
    </Box>
                    </Box>
                    </Box>
  );
};

export default Manufacturers;
