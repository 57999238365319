import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, List, ListItem, ListItemText, Divider, FormControlLabel, Checkbox,Box, Toolbar, AppBar  } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../common/axios';
import { DataGrid } from '@mui/x-data-grid';


const PartnerView = () => {
  const navigate = useNavigate();
  const location = useLocation();
    
      const { uniqueId, message } = location.state || {};
    
             const { user } = useAuth();
    const { email, name, userId, roles, userTypes} =  user || {};
    
      const [partners, setPartners] = useState([]);
    
    
    
     const [searchText, setSearchText] = useState('');
  const [selectedPartners, setSelectedPartners] = useState([]);
    
  // Filter partners based on search input
  const filteredPartners = partners.filter(
    (partner) =>
      partner.companyName.toLowerCase().includes(searchText.toLowerCase()) ||
      partner.city.toLowerCase().includes(searchText.toLowerCase()) ||
      partner.country.toLowerCase().includes(searchText.toLowerCase())
  );

  // Define columns for DataGrid
  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 200 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    {
      field: 'types',
      headerName: 'Types',
      width: 200,
          renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const types = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.type).join(', ') 
      : 'No types'; // Fallback if no capabilities

    return <span>{types}</span>; // Render the capabilities as a span
  },
    },
      {
      field: 'capabilities',
      headerName: 'Capabilities',
      width: 200,
           renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const capabilities = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.name).join(', ') 
      : 'No capabilities'; // Fallback if no capabilities

    return <span>{capabilities}</span>; // Render the capabilities as a span
  },
    },
    {
      field: 'users',
      headerName: 'Users',
      width: 250,
       renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const users = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.name).join(', ') 
      : 'No users'; // Fallback if no capabilities

    return <span>{users}</span>; // Render the capabilities as a span
  },
    
    },

    
  ];


    
    

    
    
    
    
     // Load capabilities, types, and users from REST endpoints
  useEffect(() => {
    axiosInstance.get('/api/partners').then((response) => setPartners(response.data));
      
  }, []);
            
            

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
        <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{ padding: '20px' }}>


  <>
      <Typography variant="h6" style={{ marginBottom: '20px' }}>List of Partners</Typography>

      {/* Search Text Field */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* DataGrid to display the partners */}
      <Box style={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={filteredPartners}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.partnerId}
        />
      </Box>
    </>

 

     
    </Grid>
</Box>
</Box>
  );
};

export default PartnerView;
