import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Box,  Button, TextField, MenuItem, Select, InputLabel, FormControl, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, FormLabel } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate  } from 'react-router-dom';  // To get user email from login
import axiosInstance from '../common/axios';
import LeftDrawer from '../components/LeftDrawer';
import {countryCodes} from '../common/countries';
 
const Partner = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    country: '',
    city: '',
    capabilities: [],
    types: [],
    users: [],
        officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
     businessLicenseNumber: '',
    taxIdentificationNumber: '',
    employeeCount: '',
    annualTurnover: '',
    busCountryCode: '',
    busPhoneNumber: '',
    busEmailId: '',
     altPrimaryName: '',
    altPrimaryEmail: '',
    altPrimaryCountryCode: '',
    altPrimaryPhoneNumber: '',
          estYear: '',
	website: '',
	hasExportLicense: 0, 
       partnerCertificationDTOs: [],
    enterpriseNatureDTO: { id: '' },
  });
    
    
  const handleRadioChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value, })); 
    };
    
    
      const [sameAsBilling, setSameAsBilling] = useState(false);
      const [sameAsOffice, setSameAsOffice] = useState(false);


  const [capabilitiesOptions, setCapabilitiesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
     const [error, setError] = useState('');
    
      const [enterpriseNature, setEnterpriseNature] = useState([]);
       const [certifications, setCertifications] = useState([]);

    const navigate = useNavigate(); 
  const { user } = useAuth();
  const { email, name, userId, roles, userTypes} =  user || {};
    
  // Load capabilities, types, and users from REST endpoints
  useEffect(() => {
    axiosInstance.get('/api/partner-capabilities').then((response) => setCapabilitiesOptions(response.data));
    axiosInstance.get('/api/partner-types').then((response) =>  setTypesOptions(response.data));
      if (roles.some(userRole => userRole.role === 'ROLE_ADMIN')) {
    axiosInstance.get('/api/users').then((response) => setUsersOptions(response.data));
      }
         if (roles.some(userRole => userRole.role === 'ROLE_PARTNER')) {
           setFormData({ ...formData, users: [userId] });
          }
      axiosInstance.get('/api/enterprise-nature').then((response) => setEnterpriseNature(response.data));  
      
        const fetchCertifications = async () => {
      try {
        const certificationResponse = await axiosInstance.get(`/api/certifications`);
          console.log("certificationResponse: "+ certificationResponse );
          setCertifications(certificationResponse.data);
        
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    }; 
      
      fetchCertifications();
      
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCapabilitiesChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, capabilities: value });
  };

  const handleTypesChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, types: value });
  };
    
const handleEnterpriseNatureChange = (event) => { const { value } = event.target; setFormData((prevFormData) => ({ ...prevFormData, enterpriseNatureDTO: { id: value }, })); };

  const handleUsersChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, users: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axiosInstance.post('/api/partners', formData)
      .then(response => {
        console.log("Partner data saved successfully:", response.data);
        alert("Partner data saved successfully");
        setFormData({
    companyName: '',
    country: '',
    city: '',
    capabilities: [],
    types: [],
    users: [],
        officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
                 businessLicenseNumber: '',
    taxIdentificationNumber: '',
    employeeCount: '',
    annualTurnover: '',
    busCountryCode: '',
    busPhoneNumber: '',
    busEmailId: '',
                 altPrimaryName: '',
    altPrimaryEmail: '',
    altPrimaryCountryCode: '',
    altPrimaryPhoneNumber: '',
                estYear: '',
	website: '',
	hasExportLicense: 0, 
    enterpriseNatureDTO: { id: '' },
                  partnerCertificationDTOs: [],

  });
      })
      .catch(error => {
        console.error("Error saving partner data:", error);
         alert("Error saving partner data. Please try later or check with adminstrator!!");
      });
  };
    
    
        // Handle checkbox selection for certifications
  const handleCheckboxChange = (certificationId) => {
      console.log("in the handle checkbox change");
    const isSelected = formData.partnerCertificationDTOs.some(
      (cert) => cert.certificationId === certificationId
    );

      
    if (isSelected) {
      setFormData((prev) => ({
        ...prev,
        partnerCertificationDTOs: prev.partnerCertificationDTOs.filter(
          (cert) => cert.certificationId !== certificationId
        ),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        partnerCertificationDTOs: [
          ...prev.partnerCertificationDTOs,
          { certificationId, issueDate: "", expiryDate: "" },
        ],
      }));
    }
  };

  // Handle date changes (issueDate or expirationDate)
  const handleDateChange = (certificationId, field, value) => {
    setFormData((prev) => ({
      ...prev,
      partnerCertificationDTOs: prev.partnerCertificationDTOs.map((cert) =>
        cert.certificationId === certificationId
          ? { ...cert, [field]: value }
          : cert
      ),
    }));
  };

  const handleAddressChange = (type, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], [field]: value },
    }));

    if (type === 'officeAddress' && sameAsOffice) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress, [field]: value },
      }));
    }
    if (type === 'billingAddress' && sameAsBilling) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress, [field]: value },
      }));
    }
  };
    
  const handleSameAsOffice = (event) => {
    const isChecked = event.target.checked;
    setSameAsOffice(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress },
      }));
    }
  };

  const handleSameAsBilling = (event) => {
    const isChecked = event.target.checked;
    setSameAsBilling(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress },
      }));
    }
  };

    
  const renderAddressFields = (label, type) => (
    <>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine1}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine1', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine2}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine2', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            fullWidth
            required
            value={formData[type].city}
            onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            fullWidth
            required
            value={formData[type].state}
            onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            fullWidth
            required
            value={formData[type].zipCode}
            onChange={(e) => handleAddressChange(type, 'zipCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            fullWidth
            required
            value={formData[type].country}
            onChange={(e) => handleAddressChange(type, 'country', e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );


  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
      
          <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <h2>Partner Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Company Name"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          required
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          required
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          required
        />
      </FormControl>
      
       <FormControl fullWidth margin="normal">
        <TextField
          label="Business LicenseNumber"
          name="businessLicenseNumber"
          value={formData.businessLicenseNumber}
          onChange={handleInputChange}
          required
        />
      </FormControl>
      
      <FormControl fullWidth margin="normal">
        <TextField
          label="Tax Identification Number"
          name="taxIdentificationNumber"
          value={formData.taxIdentificationNumber}
          onChange={handleInputChange}
          required
        />
      </FormControl>
            <FormControl fullWidth margin="normal">
        <TextField
          label="Employee Count"
          name="employeeCount"
          value={formData.employeeCount}
          onChange={handleInputChange}
          required
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Annual Turnover"
          name="annualTurnover"
          value={formData.annualTurnover}
          onChange={handleInputChange}
          required
        />
      </FormControl>
    
       
          <FormControl fullWidth margin="normal">
        <InputLabel id="busCountryCode-label">Country Code</InputLabel>
        <Select
          labelId="busCountryCode-label"
          id="busCountryCode"
          name="busCountryCode"
          value={formData.busCountryCode}
          onChange={(e) => setFormData({ ...formData, busCountryCode: e.target.value })}
          required
        >
          <MenuItem value="">Select Country Code</MenuItem>
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.code} ({country.country})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
     <FormControl fullWidth margin="normal">
        <TextField
          label="Business PhoneNumber"
          name="busPhoneNumber"
          value={formData.busPhoneNumber}
          onChange={handleInputChange}
          required
        />
      </FormControl>
      
           <FormControl fullWidth margin="normal">
            <TextField
              label=" Contact Email"
              fullWidth
              value={formData.busEmailId}
            onChange={(e) => setFormData({ ...formData, busEmailId: e.target.value })}
            required
            />
          </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Capabilities</InputLabel>
        <Select
          name="capabilities"
          value={formData.capabilities}
          multiple
          onChange={handleCapabilitiesChange}
        >
          {capabilitiesOptions.map((capability) => (
            <MenuItem key={capability.id} value={capability.id}>
              {capability.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

     <FormControl fullWidth margin="normal">
        <InputLabel>Partner Types</InputLabel>
        <Select
          name="types"
          value={formData.types}
          multiple
          onChange={handleTypesChange}
        >
          {typesOptions.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


<FormControl fullWidth margin="normal">
  <InputLabel>Enterprise Nature</InputLabel>
  <Select
    name="enterpriseNatureDTO"
    value={formData.enterpriseNatureDTO.id} // Access id property here
    onChange={handleEnterpriseNatureChange}
  >
    <MenuItem value="">Select Enterprise Nature</MenuItem>
    {enterpriseNature.map((entNature) => (
      <MenuItem key={entNature.id} value={entNature.id}>
        {entNature.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>


        {/*  */}
        <TextField
          fullWidth 
          label="Established Year"
          variant="outlined"
          name="estYear"
          value={formData.estYear}
          onChange={handleInputChange}
          sx={{ marginBottom: 2 }}
        />

  {/* Certifications Section */}
        <Typography variant="h6" gutterBottom>Select Certifications</Typography>

      <Grid item xs={12} sm={6}>
        {certifications.map((certification) => (
          <div key={certification.id}>
            <label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(certification.id)}
              />
              {certification.name}
            </label>
            {formData.partnerCertificationDTOs.some(
              (cert) => cert.certificationId === certification.id
            ) && (
              <div style={{ marginLeft: "20px" }}>
                <label>
                  Issue Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "issueDate",
                        e.target.value
                      )
                    }
                  />
                </label>
                <br />
                <label>
                  Expiration Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "expiryDate",
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
            )}
          </div>
        ))}
     </Grid>

        {/*  */}
        <TextField
          fullWidth 
          label="Website"
          variant="outlined"
          name="website"
          value={formData.website}
          onChange={handleInputChange}
          sx={{ marginBottom: 2 }}
        />


   <FormControl component="fieldset">
    <FormLabel component="legend">Export License</FormLabel>
     <RadioGroup aria-label="hasExportLicense" name="hasExportLicense" value={formData.hasExportLicense} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>


  {Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_PARTNER') ? (
        <TextField
          label="User Name"
          value={user.name}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      ) : Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN') ? (
      
       <FormControl fullWidth margin="normal">
        <InputLabel>Users</InputLabel>
        <Select
          name="users"
          value={formData.users}
          multiple
          onChange={handleUsersChange}
        >
          {usersOptions.map((user) => (
            <MenuItem key={user.userId} value={user.userId}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null}

     {renderAddressFields("Office Address", "officeAddress")}

      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsOffice}
            onChange={handleSameAsOffice}
            color="primary"
          />
        }
        label="Billing address is the same as office address"
      />

      {!sameAsOffice && renderAddressFields("Billing Address", "billingAddress")}

      
      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsBilling}
            onChange={handleSameAsBilling}
            color="primary"
          />
        }
        label="Shipping address is the same as billing address"
      />

      {!sameAsBilling && renderAddressFields("Shipping Address", "shippingAddress")}



             <Typography variant="h6" gutterBottom>Primary contact</Typography>
  
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Name"
              fullWidth
              value={formData.altPrimaryName}
           onChange={(e) => setFormData({ ...formData, altPrimaryName: e.target.value })}
           required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Email"
              fullWidth
              value={formData.altPrimaryEmail}
            onChange={(e) => setFormData({ ...formData, altPrimaryEmail: e.target.value })}
            required
            />
          </Grid>


          <Grid item xs={12} sm={6}>
   {/* Error Message for Phone Number */}

      {/* Phone Number Container */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="altPrimaryCountryCode-label">Country Code</InputLabel>
        <Select
          labelId="altPrimaryCountryCode-label"
          id="altPrimaryCountryCode"
          name="altPrimaryCountryCode"
          value={formData.countryCode}
          onChange={(e) => setFormData({ ...formData, altPrimaryCountryCode: e.target.value })}
          required
        >
          <MenuItem value="">Select Country Code</MenuItem>
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.code} ({country.country})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Primary Contact Phone"
              fullWidth
              value={formData.altPrimaryPhoneNumber}
            onChange={(e) => setFormData({ ...formData, altPrimaryPhoneNumber: e.target.value })}
            required
            />
          </Grid>
        </Grid>
     
  <FormControl component="fieldset">
    <FormLabel component="legend">COC, Statutory and Regulatory Requirements - Legal Compliance </FormLabel>
     <RadioGroup aria-label="cocLegal" name="cocLegal" value={formData.cocLegal} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>

  <FormControl component="fieldset">
    <FormLabel component="legend">Respect for the basic human rights, Prohibition of child labor, Health and Safety of employees</FormLabel>
     <RadioGroup aria-label="cocRightChildlabor" name="cocRightChildlabor" value={formData.cocRightChildlabor} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>


      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
</Box>
</Box>
</Box>
  );
};

export default Partner;