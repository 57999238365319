import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Button } from '@mui/material';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import CommentPopup from '../components/CommentPopup';
import CustomerReviewPopup from '../components/CustomerReviewPopup';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const OrdersPage = () => {
  
     const { user } = useAuth();
    
    const { email, name, userId, roles, userTypes} =  user || {};
  const [orders, setOrders] = useState([]);
 const navigate = useNavigate(); 
    
    const fetchOrder = async () => { 
          if (email) {
      // Fetch the orders from API based on the user's email
      
         
        let orderURL = `${backendUrl}/api/users/${userId}/order/getAll`  
        if(Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')){
            
            console.log("admin order url ")
           orderURL = `${backendUrl}/api/orders`
           }
        
      axios.get(orderURL)
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
        });
    }
    }
  useEffect(() => {
      
        fetchOrder();
  }, [email]);

    
        //review
  const [isReviewPopupOpen, setReviewPopupOpen] = useState(false);
  const openReviewPopup = () => {
      setReviewPopupOpen(true);
  }
  
  const closeReviewPopup = () => {
       setType(null);
      setReviewPopupOpen(false);
  }
  
      const handleReview = (id, type) => {
         console.log(" review opn : ", isReviewPopupOpen)
    setSelectedId(id);
    setReviewPopupOpen(true);
       setType(type);
  };

  // Columns for Data Grid
  const orderColumns = [
    { field: 'orderId', headerName: 'Order ID', width: 150 },
    { field: 'name', headerName: 'Customer', width: 200 },
    { field: 'orderStatus', headerName: 'Status', width: 150 },
    { field: 'orderCreatedDate', headerName: 'Date', width: 150 },
        {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    renderCell: (params) => {
      if (Array.isArray(roles) && roles.some(userRole => userRole.role === 'ROLE_ADMIN')) {
     
        return (
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleApprove(params.row.orderId,'order')}
              style={{ marginRight: 8 }}
            >
              Update Order
            </Button>
       </>
      );
      }
      return null; // Return nothing if status is not "Provided"
    }
  },
     {
    field: 'view',
    headerName: 'View Comment',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleViewComments(params.row.orderId, 'order')}
      >
        View Details
      </Button>
    ),
  },
     {
    field: 'review',
    headerName: 'Review',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleReview(params.row.orderId, 'order')}
      >
       Review Order
      </Button>
    ),
  }
  ];
    
    
    
  
     const [selectedId, setSelectedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
      const [type, setType] = useState(null);
     const [openPopup, setOpenPopup] = useState(false);

  const handleViewComments = (id, type) => {
    setSelectedId(id);
    setOpenPopup(true);
       setType(type);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
       setType(null);
  };

  
   

  const handleApprove = (id, type) => {
    setSelectedId(id);
    setOpenDialog(true); // Open the confirmation dialog
      setType(type);
  };

    
  const handleConfirm = async (status, comment,attachment) => {
   
      
      if(type==='order'){
          
        let  updatStatusURL = `${backendUrl}/api/orders/${selectedId}`

    
       
      const order = {
          id: selectedId,
         comments: [
      { statusId: status, comment: comment,  userId:userId  }
       ]
          
      };
          
const formData = new FormData();
formData.append("order", new Blob([JSON.stringify(order)], { type: "application/json" }));
formData.append("attachment", attachment);
      
          
         await axios.put(updatStatusURL, formData)
      .then((response) => {
        console.log('Order approved:', response.data);
        // Optionally refresh the grid or update the status locally
      })
      .catch((error) => {
        console.error('Error approving quote:', error);
      });
  }
      await fetchOrder();
      
      console.log("update order  " + userId + "status "+ status);
    setType(null);
    setOpenDialog(false); // Close the dialog after confirming
  };



const handleDecline = (id) => {
  console.log('Declined row with id:', id);
  // Add your logic to handle the decline action (e.g., update the status, make API calls, etc.)
};

  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
    <div style={{ height: 400, width: '100%' }}>
      <h2>Orders List</h2>
      <DataGrid rows={orders} columns={orderColumns} pageSize={5}  getRowId={(row) => row.orderId}  />
    </div>
 {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
        type={type}
      />
               {openPopup && (
        <CommentPopup
          open={openPopup}
          handleClose={handleClosePopup}
          rowId={selectedId}
         type={type}
        />
      )}
      
          <CustomerReviewPopup
        open={isReviewPopupOpen}
        onClose={closeReviewPopup}
        userId={userId} // Replace with dynamic customer ID as needed
        type={type}
        orderId={selectedId}
      />
            
      </Box>
      </Box>
  );
};

export default OrdersPage;
