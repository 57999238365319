import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, Chip, OutlinedInput, FormControlLabel,Checkbox, Box, AppBar, Toolbar, ListItemText, Radio, RadioGroup, FormLabel } from '@mui/material';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import {countryCodes} from '../common/countries';
import { useNavigate  } from 'react-router-dom'; 
import axiosInstance from '../common/axios';

const BuilderForm = () => {
    
  const navigate = useNavigate(); 
  const { user } = useAuth();
    
  const { email, name, roles, userTypes} =  user || {};
    
  const [formData, setFormData] = useState({
    builderName: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    country: '',
         builderCertificationDTOs: [],
         employeeCount: '',
          annualTurnover: '',
    businessLicenseNumber: '',
      taxIdentificationNumber:'',
    serviceIds: [],
       officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
          estYear: '',
	website: '',
	hasExportLicense: 0, 
  });
    
    
     const [serviceIds, setConstructionServices] = useState([]);
    
     const [certifications, setCertifications] = useState([]);
    
     // Fetch products from the backend service
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axiosInstance.get(`/api/construction-services`); // Replace with your actual API endpoint
        console.log("companyResponse: "+ response );
          setConstructionServices(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

     const fetchCertifications = async () => {
      try {
        const certificationResponse = await axiosInstance.get(`/api/certifications`);
          console.log("certificationResponse: "+ certificationResponse );
          setCertifications(certificationResponse.data);
        
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };
      
      fetchCertifications();
    fetchServices();
  }, []);


 const handleServiceChange = (event) => {
  const selectedIds = event.target.value;
  setFormData({ ...formData, serviceIds: selectedIds });
};
    
    
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/api/builders', formData);
      console.log('Builder data submitted successfully:', response.data);
        alert("Builder data submitted successfully");
        setFormData({
    builderName: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    country: '',
         builderCertificationDTOs: [],
         employeeCount: '',
          annualTurnover: '',
    businessLicenseNumber: '',
      taxIdentificationNumber:'',
    serviceIds: [],
       officeAddress: { streetAddressLine1: '', streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    billingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
    shippingAddress: { streetAddressLine1: '',  streetAddressLine2: '', city: '', state: '', zipCode: '', country: '' },
                estYear: '',
	website: '',
	hasExportLicense: 0, 
  });
        
    } catch (error) {
      console.error('Error submitting builder data:', error);
          alert("Error submitting builder data. Please contact adminstrator or try again later!!");
    }
  };
    
        const handleRadioChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value, })); 
    };
    
        
    // Handle checkbox selection for certifications
  const handleCheckboxChange = (certificationId) => {
      console.log("in the handle checkbox change");
    const isSelected = formData.builderCertificationDTOs.some(
      (cert) => cert.certificationId === certificationId
    );

      
    if (isSelected) {
      setFormData((prev) => ({
        ...prev,
        builderCertificationDTOs: prev.builderCertificationDTOs.filter(
          (cert) => cert.certificationId !== certificationId
        ),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        builderCertificationDTOs: [
          ...prev.builderCertificationDTOs,
          { certificationId, issueDate: "", expiryDate: "" },
        ],
      }));
    }
  };

  // Handle date changes (issueDate or expirationDate)
  const handleDateChange = (certificationId, field, value) => {
    setFormData((prev) => ({
      ...prev,
      builderCertificationDTOs: prev.builderCertificationDTOs.map((cert) =>
        cert.certificationId === certificationId
          ? { ...cert, [field]: value }
          : cert
      ),
    }));
  };
    
    
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [sameAsOffice, setSameAsOffice] = useState(false);
    
  const handleAddressChange = (type, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], [field]: value },
    }));

    if (type === 'officeAddress' && sameAsOffice) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress, [field]: value },
      }));
    }
    if (type === 'billingAddress' && sameAsBilling) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress, [field]: value },
      }));
    }
  };
    
  const handleSameAsOffice = (event) => {
    const isChecked = event.target.checked;
    setSameAsOffice(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.officeAddress },
      }));
    }
  };

  const handleSameAsBilling = (event) => {
    const isChecked = event.target.checked;
    setSameAsBilling(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: { ...prevState.billingAddress },
      }));
    }
  };

    
  const renderAddressFields = (label, type) => (
    <>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine1}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine1', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            fullWidth
            required
            value={formData[type].streetAddressLine2}
            onChange={(e) => handleAddressChange(type, 'streetAddressLine2', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            fullWidth
            required
            value={formData[type].city}
            onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            fullWidth
            required
            value={formData[type].state}
            onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            fullWidth
            required
            value={formData[type].zipCode}
            onChange={(e) => handleAddressChange(type, 'zipCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            fullWidth
            required
            value={formData[type].country}
            onChange={(e) => handleAddressChange(type, 'country', e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );


    
  // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
   const handleProfile = () => navigate('/profile');
    const handlePurchaseOrder = () => navigate('/purchaseorder')
    const handleSendEstimate = () => navigate('/send-estimate')
    const handleEstimate = () => navigate('/estimate')
   const handlePartner = () => navigate('/partner')
      const handleInvoice = () => navigate('/invoice')
      const handleTrader = () => navigate('/trader')
        const handleBuilder = () => navigate('/builder')
       const handlePartnerView = () => navigate('/partnerview')
      const handlePurchaseOrderList = () => navigate('/purchaseorderlist')
      const handleInvoiceView = () => navigate('/invoiceview')
         const handleReport = () => navigate('/report');
       const handleParts = () => navigate('/parts');
           const handlePartGroup = () => navigate('/partgroup');
         const handleBuilders = () => navigate('/builders');
         const handleTraders = () => navigate('/traders');
        const handleGRN = () => navigate('/grn');
    
    
    
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
       <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {/* Left-aligned Dashboard Title */}
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>

        {/* Spacer to push the name to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Right-aligned Name */}
        <Typography variant="h6" noWrap component="div">
          Welcome {name}
        </Typography>
      </Toolbar>
    </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
      handleProfile={handleProfile}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
        handlePurchaseOrder={handlePurchaseOrder}
      handleSendEstimate={handleSendEstimate}
      handleEstimate={handleEstimate}
       handlePartner={handlePartner}
      handleInvoice={handleInvoice}
      handleTrader={handleTrader}
      handleBuilder={handleBuilder}
      handlePartnerView={handlePartnerView}
      handlePurchaseOrderList={handlePurchaseOrderList}
      handleInvoiceView={handleInvoiceView}
      handleReport={handleReport}
      handleParts={handleParts}
      handlePartGroup={handlePartGroup}
      handleTraders={handleTraders}
      handleBuilders={handleBuilders}
            handleGRN={handleGRN}
          userRoles={roles}
          userTypes={userTypes}
/>
      
     <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Builder Information
          </Typography>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            label="Builder Name"
            name="builderName"
            value={formData.builderName} 
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      
        {/* Country */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Country</InputLabel>
          <Select
            name="countryCode"
            value={formData.countryCode}
            onChange={handleChange}
            required
          >
            {countryCodes.map((country) => (
              <MenuItem key={country.code} value={country.country}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
</Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>



        {/* GST Number Field */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Business License Number"
            name="businessLicenseNumber"
            value={formData.businessLicenseNumber}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        {/* Tax Identification Number Field */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Tax Identification Number"
            name="taxIdentificationNumber"
            value={formData.taxIdentificationNumber}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        {/* GST Number Field */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Employee Count"
            name="employeeCount"
            value={formData.employeeCount}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        {/* Tax Identification Number Field */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Annual Turnover"
            name="annualTurnover"
            value={formData.annualTurnover}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>



        <Grid item xs={12}>
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

 <Grid container spacing={3}>
        {/* Other form fields */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              name="serviceIds"
              value={formData.serviceIds}
              onChange={handleServiceChange}
              multiple
              input={<OutlinedInput label="services" />}
              renderValue={(selected) => (
         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {/* Map over selected IDs and display their names */}
        {selected.map((serviceId) => {
          const selectedType = serviceIds.find((type) => type.serviceId === serviceId);
          return <Chip key={serviceId} label={selectedType?.serviceName} />;
        })}
      </Box>
              )}
            >

  <MenuItem value="">
      <em>Select Category</em>
    </MenuItem>
    {serviceIds.map((type) => (
      <MenuItem key={type.serviceId} value={type.serviceId}>
        {type.serviceName}
      </MenuItem>
    ))}
            </Select>
          </FormControl>
        </Grid>
        </Grid>

   <FormControl component="fieldset">
    <FormLabel component="legend">Export License</FormLabel>
     <RadioGroup aria-label="hasExportLicense" name="hasExportLicense" value={formData.hasExportLicense} onChange={handleRadioChange} > 
         <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" /> 
    </RadioGroup>
  </FormControl>

              {/* State */}
        <TextField
          fullWidth 
          label="Established Year"
          variant="outlined"
          name="estYear"
          value={formData.estYear}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />


     


            
  {/* Certifications Section */}
        <Typography variant="h6" gutterBottom>Select Certifications</Typography>

      <Grid item xs={12} sm={6}>
        {certifications.map((certification) => (
          <div key={certification.id}>
            <label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(certification.id)}
              />
              {certification.name}
            </label>
            {formData.builderCertificationDTOs.some(
              (cert) => cert.certificationId === certification.id
            ) && (
              <div style={{ marginLeft: "20px" }}>
                <label>
                  Issue Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "issueDate",
                        e.target.value
                      )
                    }
                  />
                </label>
                <br />
                <label>
                  Expiration Date:{" "}
                  <input
                    type="date"
                    onChange={(e) =>
                      handleDateChange(
                        certification.id,
                        "expiryDate",
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
            )}
          </div>
        ))}
     </Grid>
     
  
   {/* State */}
        <TextField
          fullWidth 
          label="Website"
          variant="outlined"
          name="website"
          value={formData.website}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />


     {renderAddressFields("Office Address", "officeAddress")}

      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsOffice}
            onChange={handleSameAsOffice}
            color="primary"
          />
        }
        label="Billing address is the same as office address"
      />

      {!sameAsOffice && renderAddressFields("Billing Address", "billingAddress")}

      
      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsBilling}
            onChange={handleSameAsBilling}
            color="primary"
          />
        }
        label="Shipping address is the same as billing address"
      />

      {!sameAsBilling && renderAddressFields("Shipping Address", "shippingAddress")}

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
</Box>
</Box>
</Box>
  );
};

export default BuilderForm;
