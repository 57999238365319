import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const CommentPopup = ({ open, handleClose, rowId ,type }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
      
      console.log(" id " + rowId + "  type "+ type);
    if (rowId) {
        
        if(type === 'order'){
            // Fetch comments based on the rowId
            
    
      axios.get(`${backendUrl}/api/orders/${rowId}/comments`)
        .then(response => {
          console.log("ord comm : "+ response.data);
          setComments(response.data)
      })
        .catch(error => console.error('Error fetching comments:', error));
        } else {
            // Fetch comments based on the rowId
      axios.get(`${backendUrl}/api/quote-request/${rowId}/comments`)
        .then(response => setComments(response.data))
        .catch(error => console.error('Error fetching comments:', error));
        }
      
    }
  }, [rowId]);
    
    
    const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleString('en-US', options);
  };
    
    
    const handleViewQuote = (fileLink,fileName) => {
  
    if(fileLink === null || fileLink === undefined || fileLink === "" ){
        
        alert("There is no attachment");
        return;
    }
    
     axios({
      url: `${backendUrl}/api/quote-request/download?filePath=${fileLink}`, // Replace with your backend URL
      method: 'GET',
      responseType: 'blob', // Important to specify blob response
    })
    .then((response) => {
      // Create a URL from the response blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Suggest the filename
      link.setAttribute('download', fileName); // Replace with your desired file name
      document.body.appendChild(link);
      
      // Start download
      link.click();
      
      // Clean up
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('There was an error downloading the file!', error);
    });
   
}

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Comments</DialogTitle>
      <DialogContent>
        <List>
          {comments.map((comment) => (
            <ListItem key={comment.id}>
              <ListItemText
                primary={formatDate(comment.createdDate)}
                secondary={`${comment.name} has updated the status to ${comment.statusName} with comment ${comment.comment}`}
              />
       <br />
     <div>
{comment.estimationFileLink && ( <button onClick={() => handleViewQuote(comment.estimationFileLink, comment.estimationFileName)}> Download {comment.estimationFileName} </button> )}
    </div>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentPopup;
